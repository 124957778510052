<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Impressão</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <h2>Direção da folha</h2>
  <div class="container-layout">
    <button (click)="selecionarLayout('landscape')" [ngClass]="{ selecionado: layout === 'landscape' }">
      <span class="landscape"></span>
    </button>
    <button (click)="selecionarLayout('portrait')" [ngClass]="{ selecionado: layout === 'portrait' }">
      <span class="portrait"></span>
    </button>
  </div>
  <h2>Colunas</h2>
  <div class="container-list">
    <button *ngFor="let coluna of colunas" lines="none">
      <vasta-input type="checkbox" [label]="coluna.titulo" [(valueCheckbox)]="coluna.checked"></vasta-input>
    </button>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <cta-buttons class="ion-float-right">
      <button class="primary" (click)="enviarColunasSelecionadas()">
        <ion-icon name="print"></ion-icon>
        <span>Pré-Visualizar Impressão</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>

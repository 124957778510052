import { commonEnv } from './environment.common'

const env = {
  production: true,
  name: 'prod',
  firebaseConfig: {
    apiKey: 'AIzaSyAh4dAGic5R0nZK0e9Jv_qVGs_1MVzT4As',
    authDomain: 'fertili-dd6df.firebaseapp.com',
    databaseURL: 'https://fertili-dd6df.firebaseio.com',
    projectId: 'fertili-dd6df',
    storageBucket: 'fertili-dd6df.appspot.com',
    messagingSenderId: '854351124633',
    appId: '1:854351124633:web:3d37fe6920eba2dc244008',
    measurementId: 'G-JMNVX74SE9',
    vapid: 'BKzrlxcUjhOYvS0qM7JxzukMJUrjg-WbyDVTTyOuPA3Gm-QuJkKB3O7saKSfOhMUgPBTt5Xl4SmYrlFClKAICXM'
  },
  url: 'https://southamerica-east1-fertili-dd6df.cloudfunctions.net/sandbox'
}

export const environment = {
  ...commonEnv,
  ...env
}

import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { AnimaisService } from 'src/app/services/animais.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IReprodutorExterno } from 'src/app/utils/interfaces/animais.interface'
import { Pelagens, Racas } from 'src/app/utils/racas'
import { ModalListaRacasPage } from '../modal-lista-racas/modal-lista-racas.page'

@Component({
  selector: 'app-modal-reprodutor-externo',
  templateUrl: './modal-reprodutor-externo.page.html',
  styleUrls: ['./modal-reprodutor-externo.page.scss']
})
export class ModalReprodutorExternoPage implements OnInit {
  public isLoading = false
  public listaRacas = Racas
  public dadosAnimalExternoProp: IReprodutorExterno

  public dadosAnimalExterno: IReprodutorExterno = {
    id_propriedade: this.propriedadesCtrl?.propriedadeSelecionada.id,
    id: null,
    nome: '',
    sexo: 'macho',
    matriz: '',
    reprodutor: '',
    descricao: '',
    meta_categoriaDeRegistro: '',
    meta_composicaoRacial: [{ raca: '', percentual: '100' }],
    meta_pelagem: '',
    meta_registroDeNascimento: '',
    meta_registroDefinitivo: '',
    created_at: '',
    updated_at: '',
    hash: '',
    especie: '',
    status: 0,
    synced_at: ''
  }

  public pelagensDisponiveis: { value: string; label: string }[] = []

  constructor(
    public propriedadesCtrl: PropriedadesService,
    private animaisCtrl: AnimaisService,
    private modalCtrl: ModalController
  ) {
    this.pelagensDisponiveis = Object.keys(Pelagens).map(pelagem => {
      return {
        value: pelagem,
        label: pelagem
      }
    })
  }

  ngOnInit(): void {
    if (this.dadosAnimalExternoProp) {
      this.dadosAnimalExterno = { ...this.dadosAnimalExternoProp }
      if (!this.dadosAnimalExterno.meta_composicaoRacial) {
        this.dadosAnimalExterno.meta_composicaoRacial = [{ raca: '', percentual: '0' }]
      }
    }
  }

  async openModalListaRacas(index: number): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaRacasPage,
      cssClass: 'custom-modal'
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()
    if (data) {
      this.dadosAnimalExterno.meta_composicaoRacial[index].raca = data.raca
    }
  }

  adicionarRaca(): void {
    if (
      this.dadosAnimalExterno.meta_composicaoRacial[this.dadosAnimalExterno.meta_composicaoRacial.length - 1]
        .raca === ''
    ) {
      return
    } else {
      this.dadosAnimalExterno.meta_composicaoRacial.push({ raca: '', percentual: '0' })
    }
  }

  removerRaca(index: number): void {
    this.dadosAnimalExterno.meta_composicaoRacial.splice(index, 1)
  }

  async adicionarNovoReprodutor(): Promise<void> {
    this.isLoading = true
    const [response, error] = await this.animaisCtrl.novoReprodutorExterno(this.dadosAnimalExterno)

    if (response) {
      this.isLoading = false
      this.modalCtrl.dismiss({ success: true })
    }

    if (error) {
      console.log(error)
      this.isLoading = false
    }
  }

  async atualizarReprodutor(): Promise<void> {
    const [response, error] = await this.animaisCtrl.atualizarReprodutorExterno(
      this.dadosAnimalExterno.id,
      this.dadosAnimalExterno
    )

    if (response) {
      this.modalCtrl.dismiss({ success: true })
    }

    if (error) console.log(error)
  }

  async submit(): Promise<void> {
    this.isLoading = true
    if (this.dadosAnimalExternoProp) {
      await this.atualizarReprodutor()
      this.isLoading = false
    } else {
      await this.adicionarNovoReprodutor()
      this.isLoading = false
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }
}

import { Component, OnInit, Input } from '@angular/core'
import { ItemReorderEventDetail, ModalController, AlertController } from '@ionic/angular'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ColunasTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { TabelaConfig, TabelaDismiss } from './configuracao-tabela-types'
import { PropriedadesService } from 'src/app/services/propriedades.service'

@Component({
  selector: 'app-configuracao-tabela',
  templateUrl: './configuracao-tabela.page.html',
  styleUrls: ['./configuracao-tabela.page.scss']
})
export class ConfiguracaoTabelaPage implements OnInit {
  @Input() labelsId: string
  @Input() controleNomeNumero: string
  @Input() exibirNBrinco: number
  @Input() colunasOriginais: ColunasTabela[] = []
  @Input() configProp: TabelaConfig

  colunasFixas: ColunasTabela[] = []
  colunasModificaveis: ColunasTabela[] = []
  colunaMais: ColunasTabela[]

  ordemColunasFixasAreas = ['nome', 'lotes_nomes', 'tamanho']

  ordemColunasFixas: string[] | ColunasTabela[] = []
  colunasIgnoradas: string[] | ColunasTabela[] = []
  atualizandoColunas: boolean = false

  constructor(
    private modalCtrl: ModalController,
    private propriedadesCtrl: PropriedadesService,
    private alertCtrl: AlertController,
    private utilsCtrl: IonicUtilsService
  ) {}

  ngOnInit(): void {

    this.colunasOriginais.map((coluna) => {
      if (this.configProp && this.configProp?.ocultas?.length) {
        coluna.exibida = !this.configProp.ocultas.includes(coluna.chave)
      } else {
        coluna.exibida = coluna.exibida === false || String(coluna.exibida) === 'false' ? false : true
      }
    })

    this.verificarColunasFixas()

    this.verificarColuna()
  }

  verificarColunasFixas(): void {
    this.ordemColunasFixas = this.colunasOriginais.filter((coluna) => {
      return (
        (coluna.fixa === true || coluna.chave === 'meta_nbrinco' || coluna.chave === 'nome') &&
        coluna.tipo !== 'checkbox'
      )
    }) as ColunasTabela[]

    this.ordemColunasFixas = this.ordemColunasFixas.map((coluna) => coluna.chave)

    this.colunasIgnoradas = ['mais', 'checkbox', ...this.ordemColunasFixas]
  }

  verificarColuna(): void {
    let ordenacaoColunas = []
    if (this.configProp?.colunas && this.configProp?.colunas.length) {
      const colunasAusentes = this.colunasOriginais.filter(coluna => !this.configProp.colunas.includes(coluna.chave))
      this.configProp.colunas.map(chave => {
        const coluna = this.colunasOriginais.find(a => a.chave == chave)
        if(coluna) ordenacaoColunas.push(coluna)
      })
      ordenacaoColunas = [
        ...ordenacaoColunas,
        ...colunasAusentes
      ]
    } else {
      ordenacaoColunas = JSON.parse(JSON.stringify(this.colunasOriginais))
    }

    this.colunasModificaveis = ordenacaoColunas.filter((coluna, index, self) => {
      const primeiraOcorrencia = self.findIndex((c) => c.chave === coluna.chave) === index
      const chaves = this.colunasIgnoradas.map(a => typeof a === 'string' ? a : a.chave)
      return !chaves.includes(coluna.chave) && primeiraOcorrencia
    })

    const colunasFixasSemOrdenar = ordenacaoColunas.filter((coluna) => {
      if (this.labelsId === 'ColunasAreas') {
        return this.ordemColunasFixasAreas.includes(coluna.chave)
      } else {
        const chaves = this.ordemColunasFixas.map(a => typeof a === 'string' ? a : a.chave)
        return chaves.includes(coluna.chave)
      }
    })

    const colunasFixasTemp = []
    for (let i = 0; i < this.ordemColunasFixas.length; i++) {
      const chave = this.ordemColunasFixas[i]
      const item = colunasFixasSemOrdenar.find((coluna) => coluna.chave === chave)

      if (item) {
        colunasFixasTemp.push(item)
      }
    }

    this.colunasFixas = colunasFixasTemp

    this.colunasFixas.forEach((coluna) => {
      if (coluna.chave === 'meta_nbrinco' && this.exibirNBrinco == 0) {
        coluna.desabilitada = true
        coluna.exibida = false
      }
      if (coluna.chave === 'numeracao' && this.controleNomeNumero === 'nome') {
        coluna.desabilitada = true
        coluna.exibida = false
      }
      if (coluna.chave === 'nome' && this.controleNomeNumero === 'numero' && this.labelsId === 'colunas_animais') {
        coluna.desabilitada = true
        coluna.exibida = false
      }
    })

    this.colunaMais = ordenacaoColunas.filter((coluna) => {
      return coluna.chave === 'mais'
    })
  }

  toggleCheckbox(colunaChecada: ColunasTabela): void {
    // this.labelsAlteradas.map((coluna) => {
    //   if (coluna.chave === colunaChecada.chave) {
    //     coluna.exibida = colunaChecada.exibida
    //   }
    // })
  }

  handleReordenarTabela(event: CustomEvent<ItemReorderEventDetail>): void {
    const novoIndiceInicial = event.detail.from
    const novoIndiceFinal = event.detail.to

    const itemMovido = this.colunasModificaveis.splice(novoIndiceInicial, 1)[0]
    this.colunasModificaveis.splice(novoIndiceFinal, 0, itemMovido)

    event.detail.complete()
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  voltarTabelaParaPadrao(): void {
    this.modalCtrl.dismiss({ deVoltaPadrao: true } as TabelaDismiss)
    this.utilsCtrl.showToast('Customização da tabela removida com sucesso', 'bottom')
  }

  async alertVoltarParaPadrao(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Deseja realmente voltar as configurações para o padrão?',
      message: 'Essa ação não poderá ser desfeita.',
      buttons: [
        'Cancelar',
        {
          text: 'Remover',
          handler: (): void => {
            this.voltarTabelaParaPadrao()
          }
        }
      ]
    })

    await alert.present()
  }

  async confirmarAlteracaoTabela(): Promise<void> {
    const colunas = [
      ...this.colunasOriginais.filter(coluna => coluna.chave == 'checkbox'),
      ...this.colunasFixas,
      ...this.colunasModificaveis,
      ...this.colunaMais,
    ]

    const colunasOcultas = colunas.filter((coluna) => {
      return coluna.exibida === false && !['mais', 'checkbox'].includes(coluna.chave)
    }).map(a => a.chave)

    const colunasExibidas = colunas.map(a => a.chave)

    const modalData: TabelaDismiss = {
      config: {
        colunas: colunasExibidas,
        ocultas: colunasOcultas
      }
    }

    await this.salvarDadosTabela(modalData)
  }

  async salvarDadosTabela(dados: TabelaDismiss): Promise<void> {
    this.atualizandoColunas = true
    const [_, error] = await this.propriedadesCtrl.adicionarMetaPropriedade({
      [this.labelsId]: JSON.stringify(dados.config)
    })
    if(error) {
      console.error('error', error)
    } else {
      await this.propriedadesCtrl.getPropriedades()
      this.modalCtrl.dismiss(dados)
    }
    this.atualizandoColunas = false
  }
}

<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="!isModal">
      <ion-back-button [defaultHref]="defaultHref"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      <ng-container *ngIf="animal">
        <span class="sexo-numero-animal">
          <ion-icon
            *ngIf="animal?.sexo"
            [name]="animal?.sexo === 'macho'? 'male'  : 'female'"
            [color]="animal?.sexo === 'macho'? 'male'  : 'female'"
          ></ion-icon>
          <strong *ngIf="animal?.numeracao">#{{animal?.numeracao}}</strong> {{animal?.nome}}
        </span>
      </ng-container>
      <ion-skeleton-text
        *ngIf="!animal"
        style="width: 10%; height: 15px; border-radius: 6px"
        animated
      ></ion-skeleton-text>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button disabled *ngIf="!isModal">
        <ion-icon name="help" color="primary"></ion-icon>
      </ion-button>
      <ion-button *ngIf="isModal" (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <!-- , 'Histórico' -->
  <header-tabs
    [tabs]="['Geral', 'Custos', 'Manejos', 'Genealogia']"
    [tabActive]="abaAtiva"
    (tabActiveChange)="changeTab($event)"
  ></header-tabs>
</ion-header>
<ion-content>
  <div [ngClass]="{'ion-hide': abaAtiva !== 'Genealogia'}">
    <genealogia
      [nomeAnimal]="animal?.numeracao || animal?.nome"
      [genealogia]="arvoreGenealogica"
      [permitirNavegacao]="!isModal"
    ></genealogia>
  </div>
  <div [ngClass]="{'ion-hide': abaAtiva !== 'Custos'}">
    <kpis-cards [cardsInfo]="custosCards" [valores]="custosValores"></kpis-cards>
    <cta-buttons *ngIf="hash_compra || hash_venda">
      <button (click)="abrirTransacao('compra')" *ngIf="hash_compra">
        <ion-icon name="cash"></ion-icon>
        <span>Consultar Compra</span>
      </button>
      <button (click)="abrirTransacao('venda')" *ngIf="hash_venda">
        <ion-icon name="cash"></ion-icon>
        <span>Consultar Venda</span>
      </button>
    </cta-buttons>
    <vasta-tabela 
      [config]="custosConfig" 
      [campos]="custosCampos" 
      [labels]="custosLabels" 
      [status]="carregamentoCustos" 
      (retentar)="getCustos()"
      (selecionado)="abrirManejosDoDia($event)"
    ></vasta-tabela>
  </div>
  <div [ngClass]="{'oculta-geral': abaAtiva !== 'Geral'}">
    <cta-buttons>
      <data-seletor
        *ngIf="!isModal"
        label="Adicionar Manejo"
        icon="mais"
        (selecionado)="abrirManejo($event)"
        class="permissao-escrita-animais acao-cadastro"
      ></data-seletor>
      <button
        (click)="openModalBovino()"
        class="permissao-escrita-animais acao-cadastro"
        [disabled]="!animal || !arvoreGenealogica || isLoading"
      >
        <ion-icon name="pencil-outline"></ion-icon>
        <span>Editar Animal</span>
      </button>
      <button [disabled]="true">
        <ion-icon name="share"></ion-icon>
        <span>Compartilhar</span>
      </button>
    </cta-buttons>

    <div class="container-masonry">
      <div class="masonry_animal{{masonryTimestamp}}">
        <div class="custom-card">
          <div class="custom-card-wrapper">
            <div class="dados-animal">
              <div class="header" *ngIf="animal">
                <ng-template [ngIf]="animal?.profile">
                  <div class="animal-imagem">
                    <img [src]="animal?.profile" />
                    <div class="excluir permissao-escrita-animais" (click)="alertConfirmDeletarFotoAnimal()">
                      <ion-icon name="trash-outline"></ion-icon>
                    </div>
                    <div class="ampliar" (click)="ampliarFotoAnimal()">
                      <ion-icon name="search-outline"></ion-icon>
                    </div>
                  </div>
                </ng-template>
                <ng-template [ngIf]="!animal?.profile">
                  <div class="not-img permissao-escrita-animais">
                    <enviar-imagem [avatar]="true" (imagemSrc)="mudarFotoAnimal($event)"></enviar-imagem>
                    <label for="add-img-perfil">
                      <ion-icon name="cloud-upload"></ion-icon>
                      Enviar Imagem
                    </label>
                  </div>
                </ng-template>

                <div class="header-content">
                  <div class="header-nome" *ngIf="animal">
                    <strong *ngIf="animal?.numeracao">#{{animal?.numeracao}}</strong>
                    {{animal?.nome}} {{!animal?.nome && !animal?.numeracao ? 'Sem Identificação' : ''}}
                  </div>
                  <div class="header-badges">
                    <span
                      class="geral"
                      *ngIf="animal?.situacao && animal?.situacao !== 'vivo'"
                      [class.morto]="animal?.situacao == 'morto'"
                      [class.perdido]="animal?.situacao == 'perdido'"
                      [class.vendido]="animal?.situacao == 'vendido'"
                      [class.abatido]="animal?.situacao == 'movido'"
                      >{{animal?.situacao}}</span
                    >
                    <span class="reprodutiva" *ngIf="animal?.situacao_reprodutiva">
                      {{situacoesReprodutivas[animal?.situacao_reprodutiva] || animal?.situacao_reprodutiva}}
                    </span>
                    <span class="brinco" *ngIf="animal?.meta_nbrinco">
                      <ion-icon name="brinco"></ion-icon>
                      {{animal?.meta_nbrinco}}
                    </span>
                  </div>
                </div>
              </div>
              <ion-row *ngIf="!animal">
                <ion-col size="auto" class="ion-no-padding">
                  <ion-skeleton-text animated style="width: 56px; height: 56px; border-radius: 7px"></ion-skeleton-text>
                </ion-col>
                <ion-col>
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
              <div class="card-data-grid">
                <div class="card-data">
                  <span class="label">Sexo</span>
                  <span class="value">
                    {{animal?.sexo === 'macho'? 'Macho' : animal?.sexo === 'femea'? 'Fêmea' : ''}}
                    <ion-icon
                      *ngIf="animal?.sexo"
                      [name]="animal?.sexo === 'macho'? 'male'  : 'female'"
                      [color]="animal?.sexo === 'macho'? 'male'  : 'female'"
                    ></ion-icon>
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 40%"></ion-skeleton-text>
                </div>
                <div class="card-data">
                  <span class="label">Nascimento</span>
                  <span class="value" *ngIf="animal"> {{animal?.data_nascimento | timestamp: 'data'}} </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>
                <div class="card-data">
                  <span class="label">Entrada no Sistema</span>
                  <span class="value" *ngIf="animal">{{animal?.created_at | timestamp: 'data'}}</span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>
                <div class="card-data" *ngIf="animal?.tipo_entrada === 'aquisicao' && animal?.meta_data_entrada">
                  <span class="label">Entrada na Fazenda</span>
                  <span class="value" *ngIf="animal">{{animal?.meta_data_entrada | timestamp: 'data'}}</span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>
                <div class="card-data" *ngIf="idadeDoAnimal?.length">
                  <span class="label">Idade</span>
                  <span class="value" *ngIf="animal">{{idadeDoAnimal}}</span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 35%"></ion-skeleton-text>
                </div>
                <div class="card-data">
                  <span class="label">Lote</span>
                  <span class="value" *ngIf="animal">
                    {{animal?.extra_lote || 'Sem Lote'}}
                    <small *ngIf="animal?.data_manejo_lote" [title]="animal?.data_manejo_lote | timestamp: 'data'">{{animal?.data_manejo_lote | timestamp: 'data'}}</small>
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>
                <div class="card-data" *ngIf="animal?.extra_area">
                  <span class="label">Área</span>
                  <span class="value">{{animal?.extra_area}}</span>
                </div>
                <div class="card-data" *ngIf="animal?.aptidao">
                  <span class="label">Aptidão</span>
                  <span class="value titlecase">{{animal?.aptidao}}</span>
                </div>
                <div style="width: 100%; display: block"></div>
                <div class="card-data" style="width: 100%" *ngIf="animal?.meta_pelagem">
                  <span class="label">Pelagem</span>
                  <span class="value">
                    {{animal?.meta_pelagem}}
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 55%"></ion-skeleton-text>
                </div>
                <div class="card-data" style="width: 100%">
                  <span class="label">Raça</span>
                  <span class="value" *ngIf="animal">
                    {{animal?.meta_raca_secundaria_composicao || animal?.meta_raca_terciaria_composicao ?
                    animal?.meta_raca_composicao : ''}} {{racas[animal?.raca]}} {{animal?.meta_puro_origem == '1' ?
                    'P.O.' : ''}}
                    <br *ngIf="animal?.meta_raca_secundaria_composicao" />
                    {{animal?.meta_raca_secundaria_composicao}} {{racas[animal?.meta_raca_secundaria] ||
                    animal?.meta_raca_secundaria}}
                    <br *ngIf="animal?.meta_raca_terciaria_composicao" />
                    {{animal?.meta_raca_terciaria_composicao}} {{racas[animal?.meta_raca_terciaria] ||
                    animal?.meta_raca_terciaria}}
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 55%"></ion-skeleton-text>
                </div>
                <div class="card-data" *ngIf="animal?.observacao" style="width: 100%">
                  <span class="label">Observação</span>
                  <span class="value" style="white-space: initial">{{animal.observacao}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <div class="custom-card-wrapper">
            <div class="dados-pesagens">
              <h2>Genealogia e Registros</h2>

              <button class="link" (click)="abaAtiva = 'Genealogia'">
                <ion-icon name="document-text-outline"></ion-icon>
                Árvore
              </button>

              <ion-skeleton-text
                *ngIf="isLoading"
                animated
                style="width: 100%; height: 120px; border-radius: 6px"
              ></ion-skeleton-text>

              <div *ngIf="!isLoading && !animal?.meta_registroDeNascimento && !animal?.meta_registroDefinitivo" class="msg-sem-dados">
                <p>Nenhum registro</p>
              </div>

              <div class="card-data-grid">
                <div class="card-data" *ngIf="animal?.meta_registroDeNascimento" style="width: 100%">
                  <span class="label">RGN</span>
                  <span class="value">
                    {{animal?.meta_registroDeNascimento}}
                    <small *ngIf="animal?.meta_dataRegistroDeNascimento" [title]="animal?.meta_dataRegistroDeNascimento | timestamp: 'data'">{{animal?.meta_dataRegistroDeNascimento | timestamp: 'data'}}</small>
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>
                <div class="card-data" *ngIf="animal?.meta_registroDefinitivo" style="width: 100%">
                  <span class="label">RGD</span>
                  <span class="value">
                    {{animal?.meta_registroDefinitivo}}
                    <small *ngIf="animal?.meta_dataRegistroDefinitivo" [title]="animal?.meta_dataRegistroDefinitivo | timestamp: 'data'">{{animal?.meta_dataRegistroDefinitivo | timestamp: 'data'}}</small>
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>
                
                <div class="linha" *ngIf="animal?.genealogia_nome_pai || animal?.genealogia_numero_pai || animal?.genealogia_pai_rgn || animal?.genealogia_pai_rgd"></div>

                <div
                  class="card-data"
                  *ngIf="animal?.genealogia_nome_pai || animal?.genealogia_numero_pai"
                  (click)="navegarPorBovinos(animal?.genealogia_reprodutor_interno)"
                >
                  <span class="label">Pai</span>
                  <span class="value">
                    <ion-icon name="male" color="male"></ion-icon>
                    <b *ngIf="animal?.genealogia_numero_pai">{{animal?.genealogia_numero_pai}}</b>
                    {{ animal?.genealogia_nome_pai }}
                  </span>
                </div>

                <div class="card-data" *ngIf="animal?.genealogia_pai_rgn" style="width: 100%">
                  <span class="label">RGN</span>
                  <span class="value">
                    {{animal?.genealogia_pai_rgn}}
                    <small *ngIf="animal?.genealogia_pai_rgn_data" [title]="animal?.genealogia_pai_rgn_data | timestamp: 'data'">{{animal?.genealogia_pai_rgn_data | timestamp: 'data'}}</small>
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>
                <div class="card-data" *ngIf="animal?.genealogia_pai_rgd" style="width: 100%">
                  <span class="label">RGD</span>
                  <span class="value">
                    {{animal?.genealogia_pai_rgd}}
                    <small *ngIf="animal?.genealogia_pai_rgd_data" [title]="animal?.genealogia_pai_rgd_data | timestamp: 'data'">{{animal?.genealogia_pai_rgd_data | timestamp: 'data'}}</small>
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>

                <div class="linha" *ngIf="animal?.genealogia_nome_mae || animal?.genealogia_numero_mae || animal?.genealogia_mae_rgn || animal?.genealogia_mae_rgd"></div>

                <div
                  class="card-data"
                  *ngIf="animal?.genealogia_nome_mae || animal?.genealogia_numero_mae"
                  (click)="navegarPorBovinos(animal?.genealogia_matriz_interna)"
                >
                  <span class="label">Mãe</span>
                  <span class="value">
                    <ion-icon name="female" color="female"></ion-icon>
                    <b *ngIf="animal?.genealogia_numero_mae">{{animal?.genealogia_numero_mae}}</b>
                    {{ animal?.genealogia_nome_mae }}
                  </span>
                </div>

                <div class="card-data" *ngIf="animal?.genealogia_mae_rgn" style="width: 100%">
                  <span class="label">RGN</span>
                  <span class="value">
                    {{animal?.genealogia_mae_rgn}}
                    <small *ngIf="animal?.genealogia_mae_rgn_data" [title]="animal?.genealogia_mae_rgn_data | timestamp: 'data'">{{animal?.genealogia_mae_rgn_data | timestamp: 'data'}}</small>
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>
                <div class="card-data" *ngIf="animal?.genealogia_mae_rgd" style="width: 100%">
                  <span class="label">RGD</span>
                  <span class="value">
                    {{animal?.genealogia_mae_rgd}}
                    <small *ngIf="animal?.genealogia_mae_rgd_data" [title]="animal?.genealogia_mae_rgd_data | timestamp: 'data'">{{animal?.genealogia_mae_rgd_data | timestamp: 'data'}}</small>
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 50%"></ion-skeleton-text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <div class="custom-card-wrapper">
            <div class="dados-pesagens">
              <h2>Pesagens</h2>

              <button class="link" (click)="abaAtiva = 'Manejos'; getTodosManejos()">
                <ion-icon name="document-text-outline"></ion-icon>
                Mais
              </button>

              <ion-skeleton-text
                *ngIf="!showChartPesagens && isLoading"
                animated
                style="width: 100%; height: 120px; border-radius: 6px"
              ></ion-skeleton-text>

              <app-grafico
                *ngIf="showChartPesagens"
                [labels]="labelsPesagens"
                [dataset]="[dataSetPesagens]"
              ></app-grafico>

              <div class="card-data-grid">
                <div class="card-data">
                  <span class="label">Último Peso</span>
                  <span class="value" *ngIf="animal">
                    {{animal?.pesagem_peso || 0}} kg
                    <small>{{animal.pesagem_data | timestamp: 'data'}}</small>
                  </span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 45%"></ion-skeleton-text>
                </div>
                <div class="card-data">
                  <span class="label">GMD Último Peso</span>
                  <span class="value" *ngIf="animal && !planoBasicoCtrl.isPlanoBasico">
                    {{animal?.pesagem_gmd || 0}} kg
                    <div class="indicador verde"></div>
                  </span>
                  <recurso-pro 
                    *ngIf="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('indicadores', 'gmd')" 
                    categoria="indicadores" 
                    label="GMD Último Peso" 
                    item="gmd"
                  ></recurso-pro>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 45%"></ion-skeleton-text>
                </div>

                <div class="card-data">
                  <span class="label">Peso ao Nascer</span>
                  <span class="value" *ngIf="animal">{{animal.pesagem_nascimento || 0}} kg</span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 40%"></ion-skeleton-text>
                </div>
                <div class="card-data">
                  <span class="label">Peso no Desmame</span>
                  <span class="value" *ngIf="animal">{{animal.pesagem_desmame || 0}} kg</span>
                  <ion-skeleton-text *ngIf="!animal" animated style="width: 40%"></ion-skeleton-text>
                </div>
              </div>

              <div class="linha"></div>

              <div class="tabela-simples">
                <div class="row head" *ngIf="pesagens.length">
                  <div class="col">Data</div>
                  <div class="col">Peso</div>
                  <div class="col">Gmd</div>
                </div>

                <ng-container *ngIf="!pesagens.length && isLoading">
                  <div *ngFor="let item of [1,2,3]" class="row" style="margin-bottom: 10px">
                    <div class="col">
                      <ion-skeleton-text animated style="width: 90%; border-radius: 4px"></ion-skeleton-text>
                    </div>
                    <div class="col">
                      <ion-skeleton-text animated style="width: 70%; border-radius: 4px"></ion-skeleton-text>
                    </div>
                    <div class="col" style="display: flex; justify-content: flex-end">
                      <ion-skeleton-text animated style="width: 30%; border-radius: 4px"></ion-skeleton-text>
                    </div>

                    <div class="row">
                      <div class="col">
                        <ion-skeleton-text animated style="width: 90%; border-radius: 4px"></ion-skeleton-text>
                      </div>
                      <div class="col b">
                        <ion-skeleton-text animated style="width: 70%; border-radius: 4px"></ion-skeleton-text>
                      </div>
                      <div class="col">
                        <ion-skeleton-text animated style="width: 30%; border-radius: 4px"></ion-skeleton-text>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="animal">
                  <div *ngFor="let item of dadosSimpleTablePesagens" class="row">
                    <div class="col">{{item.data_pesagem | timestamp: 'data'}}</div>
                    <div class="col b">{{item.peso_total | number: '1.0-2'}} kg</div>
                    <div *ngIf="!planoBasicoCtrl.isPlanoBasico" class="col">{{item.gmd_medio | number: '1.0-3' }} kg</div>
                    <recurso-pro *ngIf="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('indicadores', 'gmd')" item="gmd" categoria="indicadores"></recurso-pro>
                  </div>
                </ng-container>
                <div *ngIf="!isLoading && !pesagens.length" class="msg-sem-dados">
                  <p>Nenhuma pesagem registrada</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <div class="custom-card-wrapper">
            <div class="dados-historicoReprodutivo">
              <h2>Histórico Reprodutivo</h2>

              <button class="link" (click)="abaAtiva = 'Manejos'; getTodosManejos()">
                <ion-icon name="document-text-outline"></ion-icon>
                Mais
              </button>

              <div class="card-data-grid">
                <div class="card-data" *ngIf="animal?.meta_partos_cache">
                  <span class="label">Partos registrados</span>
                  <span class="value">
                    {{animal?.meta_partos_cache == 1 ? animal?.meta_partos_cache + ' Parto' : animal?.meta_partos_cache + ' Partos'}}
                  </span>
                </div>

                <div class="card-data" *ngIf="animal?.meta_partos">
                  <span class="label">Partos não registrados</span>
                  <span class="value">
                    {{animal?.meta_partos == 1 ? animal?.meta_partos + ' Parto' : animal?.meta_partos + ' Partos'}}
                  </span>
                </div>

                <div class="card-data" *ngIf="animal?.sexo === 'femea' && categorias[animal?.extra_categoria]">
                  <span class="label">Categoria</span>
                  <span class="value"> {{categorias[animal?.extra_categoria]}} </span>
                </div>
                <div class="card-data" *ngIf="animal?.situacao_reprodutiva">
                  <span class="label">Situação Reprodutiva</span>
                  <span class="value" *ngIf="animal"
                    >{{situacoesReprodutivas[animal.situacao_reprodutiva] || animal.situacao_reprodutiva |
                    titlecase}}</span
                  >
                </div>
                <div class="card-data" *ngIf="animal?.meta_previsao_de_parto && animal?.sexo == 'femea'">
                  <span class="label">Previsão de Parto</span>
                  <span class="value" *ngIf="animal">{{animal.meta_previsao_de_parto | timestamp: 'data'}}</span>
                </div>
              </div>

              <div class="linha"></div>

              <div class="tabela-simples table-historico">
                <div class="row head" *ngIf="manejosReprodutivos?.length">
                  <div class="col">Data</div>
                  <div class="col">Manejo</div>
                </div>

                <ng-container *ngIf="!manejosReprodutivos">
                  <div *ngFor="let item of [1,2,3]" class="row">
                    <div class="col">
                      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                    </div>
                    <div class="col info">
                      <span>
                        <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                      </span>
                      <small>
                        <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
                      </small>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="manejosReprodutivos">
                  <button
                    *ngFor="let item of manejosReprodutivosCard"
                    class="row"
                    (click)="abrirManejo(item.data, true)"
                  >
                    <div class="col">{{item.data | timestamp: 'data'}}</div>
                    <div class="col info">
                      <span>{{labelsManejosReprodutivos[item.procedimento]}}</span>
                      <small class="titlecase">{{item.resultado}}</small>
                    </div>
                  </button>
                </ng-container>
              </div>

              <div *ngIf="!isLoading && !manejosReprodutivos?.length" class="msg-sem-dados">
                <p>Nenhum registro</p>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <div class="custom-card-wrapper">
            <div class="dados-evolucaoAnimal">
              <h2>Evolução do Animal</h2>

              <div class="tabela-simples evolucao-animal">
                <div class="row head" *ngIf="evolucao?.length">
                  <div class="col">Foto</div>
                  <div class="col">Idade</div>
                  <div class="col">Peso</div>
                </div>

                <ng-container *ngIf="!evolucao && isLoading">
                  <div *ngFor="let item of [1,2,3]" class="row">
                    <div class="col" style="margin-bottom: 5px">
                      <ion-thumbnail>
                        <ion-skeleton-text animated style="border-radius: 7px"></ion-skeleton-text>
                      </ion-thumbnail>
                    </div>
                    <div class="col info">
                      <span>
                        <ion-skeleton-text animated style="width: 60%; border-radius: 4px"></ion-skeleton-text>
                      </span>
                      <span>
                        <ion-skeleton-text animated style="width: 40%; border-radius: 4px"></ion-skeleton-text>
                      </span>
                    </div>
                    <div class="col">
                      <ion-skeleton-text animated style="width: 100%; border-radius: 4px"></ion-skeleton-text>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="evolucao">
                  <div *ngFor="let item of evolucao" class="row">
                    <div class="col">
                      <img [src]="item.link" (click)="openModalEvolucaoAnimal(item)" />
                    </div>
                    <div class="col info">
                      <span>{{item.idade_animal || 0}} {{item.idade_animal == 1? 'Mês' : 'Meses'}}</span>
                    </div>
                    <div class="col b">{{item.peso | number: '2.0-0'}} kg</div>
                  </div>
                </ng-container>

                <div *ngIf="!isLoading && !evolucao?.length" class="msg-sem-dados">
                  <p>Nenhum registro</p>
                </div>
              </div>

              <div class="container-adicionar-evolucao-animal">
                <button (click)="openModalEvolucaoAnimal()" [disabled]="!evolucao">
                  <ion-icon name="add-circle-sharp"></ion-icon>
                </button>
                <span>Adicionar</span>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card" *ngIf="arvoreGenealogica?.crias?.length">
          <div class="custom-card-wrapper">
            <div class="dados-crias">
              <h2>Crias</h2>

              <div class="tabela-simples">
                <ng-template ngFor let-filho [ngForOf]="arvoreGenealogica.crias">
                  <div class="cria-single">
                    <div class="row" style="cursor: pointer" (click)="filho?.id_propriedade == animal?.id_propriedade ? navegarPorBovinos(filho?.hash) : ''">
                      <div class="col">
                        <ion-icon
                          [name]="filho?.sexo === 'macho'? 'male' : 'female'"
                          [color]="filho?.sexo === 'macho'? 'male' : 'female'"
                        ></ion-icon>
                        <strong *ngIf="filho?.numeracao">#{{filho?.numeracao}}</strong>
                        {{filho?.nome}}
                      </div>
                      <div class="col col-status">
                        <span
                          class="tag"
                          [class.morto]="filho?.situacao == 'morto'"
                          [class.perdido]="filho?.situacao == 'perdido'"
                          [class.vendido]="filho?.situacao == 'vendido'"
                          [class.abatido]="filho?.situacao == 'movido'"
                        >
                          {{filho?.situacao}}
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col label">Nascimento</div>
                      <div class="col right">
                        <span *ngIf="filho?.pesagem_nascimento"><b>{{filho?.pesagem_nascimento | number: '1.0-2'}} kg</b> |</span>
                        {{filho?.data_nascimento | timestamp: 'data'}}
                      </div>
                    </div>
                    <div class="row" *ngIf="filho?.meta_data_desmame">
                      <div class="col label">Desmame</div>
                      <div class="col right">
                        <span *ngIf="filho?.pesagem_desmame"><b>{{filho?.pesagem_desmame | number: '1.0-2'}} kg</b> |</span>
                        {{filho?.meta_data_desmame | timestamp: 'data'}}
                      </div>
                    </div>
                    <div class="row" *ngIf="filho?.genealogia_nome_pai || filho?.genealogia_numero_pai">
                      <div class="col label">Pai</div>
                      <div class="col right">
                        <ion-icon name="male" color="male"></ion-icon>
                        <b *ngIf="filho?.genealogia_numero_pai">#{{filho?.genealogia_numero_pai}}</b>
                        {{filho?.genealogia_nome_pai}}
                      </div>
                    </div>
                    <div class="row" *ngIf="filho?.id_propriedade !== animal?.id_propriedade" (click)="filho?.id_propriedade == animal?.id_propriedade ? navegarPorBovinos(filho?.hash) : ''">
                      <div class="col label">Propriedade</div>
                      <div class="col right">
                        {{filho?.propriedade}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <div class="custom-card-wrapper">
            <div class="dados-historicoSanitario">
              <h2>Histórico Sanitário</h2>

              <button class="link" (click)="abaAtiva = 'Manejos'; getTodosManejos()">
                <ion-icon name="document-text-outline"></ion-icon>
                Mais
              </button>

              <div class="tabela-simples table-historico">
                <div class="row head" *ngIf="manejosSanitarios?.length">
                  <div class="col">Data</div>
                  <div class="col">Manejo</div>
                </div>

                <ng-container *ngIf="isLoading && !manejosSanitarios">
                  <div *ngFor="let item of [1,2,3]" class="row">
                    <div class="col">
                      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                    </div>
                    <div class="col info">
                      <span>
                        <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                      </span>
                      <small>
                        <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
                      </small>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="manejosSanitarios">
                  <div *ngFor="let item of manejosSanitariosCard" class="row">
                    <div class="col">{{item.data | timestamp: 'data'}}</div>
                    <div class="col info" *ngIf="item.procedimento">
                      <span>{{item.descricao}}</span>
                      <small>{{labelsSituacoesSanitarias[item.procedimento]}}</small>
                    </div>
                    <div class="col info" *ngIf="item.estoque">
                      <span>{{item.estoque}}</span>
                      <small>{{item.quantidade | number: '1.0-1'}} {{item.unidade}}{{item.unidade == 'unidade' && item.quantidade > 1 ? 's' : ''}}</small>
                    </div>
                  </div>
                </ng-container>

                <div *ngIf="!isLoading && !manejosSanitarios?.length" class="msg-sem-dados">
                  <p>Nenhum registro</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <div class="custom-card-wrapper">
            <div class="dados-historicoGeral">
              <h2>Histórico</h2>

              <button class="link" (click)="abaAtiva = 'Manejos'; getTodosManejos()">
                <ion-icon name="document-text-outline"></ion-icon>
                Mais
              </button>

              <div class="tabela-simples table-historico">
                <div class="row head" *ngIf="manejosGerais?.length">
                  <div class="col">Data</div>
                  <div class="col">Manejo</div>
      </div>

                <ng-container *ngIf="isLoading && !manejosGerais">
                  <div *ngFor="let item of [1,2,3]" class="row">
                    <div class="col">
                      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
    </div>
                    <div class="col info">
                      <span>
                        <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                      </span>
                      <small>
                        <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
                      </small>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="manejosGerais">
                  <div *ngFor="let item of manejosGeraisCard" class="row">
                    <div class="col">{{item.data | timestamp: 'data'}}</div>

                    <div class="col info">
                      <span class="value" *ngIf="item.procedimento === 'venda' || item.procedimento === 'secagem'">
                        {{labelsManejosGerais[item.procedimento] || item.procedimento}}
                      </span>
                      <span class="value" *ngIf="item.procedimento === 'observacao'">{{item.observacao_manejo}}</span>
                      <span class="value" *ngIf="item.procedimento === 'movimentacao-lote'">{{item.nome_lote}}</span>
                      <span class="value" *ngIf="item.procedimento === 'movimentacao-confinamento'">{{item.nome_lote}}</span>
                      <span class="value" *ngIf="item.procedimento === 'numero-brinco-eletronico'">{{item.brinco_eletronico}}</span>
                      <span class="value" *ngIf="item.procedimento === 'numero-controle'">{{item.numero_novo}}</span>
                      <small class="label" *ngIf="item.procedimento !== 'venda' && item.procedimento !== 'secagem'">
                        {{labelsManejosGerais[item.procedimento] || item.procedimento}}
                      </small>
                    </div>
                  </div>
                </ng-container>

                <div *ngIf="!isLoading && !manejosGeraisCard?.length" class="msg-sem-dados">
                  <p>Nenhum registro</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <cta-buttons *ngIf="!isModal">
      <button class="danger-clear permissao-escrita-animais" (click)="alertConfirmDeleteAnimal(animal)">
        <ion-icon name="trash"></ion-icon>
        <span>Remover animal</span>
      </button>
    </cta-buttons>
  </div>
  <div [ngClass]="{'ion-hide': abaAtiva !== 'Histórico'}">
    <vasta-tabela
      [config]="configHistorico"
      [labels]="labelsHistorico"
      [campos]="historico"
      [status]="carregamentoHistoricoAnimal"
      (retentar)="getHistoricoAnimal(hash)"
    >
    </vasta-tabela>
    <div class="msg-sem-dados-tabela" *ngIf="carregamentoHistoricoAnimal === 'sucesso' && !historico?.length">
      <p>Nenhum dado</p>
    </div>
  </div>
  <div [ngClass]="{'ion-hide': abaAtiva !== 'Manejos'}">
    <cta-buttons *ngIf="!isModal">
      <data-seletor label="Adicionar Manejo" icon="mais" (selecionado)="abrirManejo($event)"></data-seletor>
    </cta-buttons>

    <vasta-tabela
      [status]="carregamentoListaTodosManejos"
      [labels]="labelsTodosManejos"
      [config]="configTodosManejos"
      [campos]="dadosListaTodosManejos"
      [optionsSelecionados]="optionsButtonsSelecionadosListaTodosManejos"
      [optionsButtons]="optionsButtonsTodosManejos"
      [(hashsSelecionados)]="hashsSelecionadosTodosManejos"
      (hashsSelecionadosChange)="getHashsSelecionados($event)"
      [(novaOrdenacao)]="novaOrdenacaoTodos"
      [(novoFiltro)]="novoFiltroTodos"
      [filtrosExtras]="filtrosExtrasTodos"
      [opcoesFiltros]="opcoesFiltros"
      (novoFiltroChange)="getTodosManejos()"
      (selecionado)="abrirManejo($event, true)"
      (retentar)="getTodosManejos()"
      [(paginate)]="paginateTodosManejos"
      (paginateChange)="paginaAlterada()"
    ></vasta-tabela>

    <div class="placeholder-vazio" *ngIf="carregamentoListaTodosManejos === 'sucesso' && !dadosListaTodosManejos?.length">
      <p>Nenhum manejo encontrado</p>
    </div>
  </div>
  <div class="data-cadastro" *ngIf="animal && arvoreGenealogica">
    <p>Cadastrado em <b>{{dataCadastroAnimal}}</b>, atualizado <b>{{dataUltimaAtualizacaoAnimal}}</b></p>
  </div>
</ion-content>

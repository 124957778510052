import { Injectable } from '@angular/core'
import { IRelatorioArrobasProduzidas, RelatorioCustoDaArroba } from '../utils/interfaces/dashboard.interface'
import { IFinancasTransacao } from '../utils/interfaces/financas.interface'
import { NovoQueryParamsModel, QueryParamsmModel } from '../utils/interfaces/query-params.interface'
import {
  IChuvas,
  IDesembolsoCabecaMes,
  IEstoqueMensal,
  IGmdGlobalMedio,
  IMapaSanitario,
  IMortalidade,
  IRelatorioConsumoNutricional,
  IRelatorioCustoAnimais,
  IRelatorioDesempenhoNutricional,
  IRelatorioPesagem,
  IRelatorioProducaoDeLeite,
  IRelatorioProducaoDeLeitePorVaca,
  ITotaisGmdGlobalMedio,
  ITotaisMortalidade,
  ITotaisPerda,
  ITotaisRelatorioPesagem,
  ITotalChuvas,
  ManutencaoGenealogiaENascimento,
  RelatorioAbortos,
  RelatorioDesmames,
  RelatorioFluxoDeCaixa,
  RelatorioIntervaloEntrePartos,
  RelatorioPartos,
  RelatorioPerda,
  RelatorioPrevisaoPartos,
  RelatorioTodosReprodutivos,
  RelatorioVacasAptasPosParto,
  RelatorioVacasNaoTocadas,
  ResponseTodasChuvas
} from '../utils/interfaces/relatorios.interface'
import { ApiService } from './api/api.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'

interface ResponseMovimentacoes {
  dados: IRelatorioArrobasProduzidas[]
  arrobaPorCab: number
  arrobaPorHA: number
  gmd_global: number
  rebanho_medio: number
  arrobas_produzidas: number
  kgs_produzidos: number
  compras: number
  vendas: number
  peso_inicial: number
  peso_final: number
}

interface ICustosAnimal {
  manejo?: string
  data?: string
  qtd?: number
  item?: string
  duracao?: number
  valor_kg?: number
  custo_total?: number
  unidade?: string
}

@Injectable({
  providedIn: 'root'
})
export class RelatoriosService {
  constructor(private apiCtrl: ApiService, private permissoesCtrl: PermissoesUsuarioService) { }

  async estoqueMensal(
    periodo?: { de: string; ate: string },
    paginate?: { offset: number; limit: number }
  ): Promise<[{ dados: IEstoqueMensal[] }, Error?]> {
    try {
      let queryParams: QueryParamsmModel = {}

      if (periodo) {
        queryParams = {
          de: periodo.de,
          ate: periodo.ate
        }
      }

      if (paginate) {
        queryParams.paginate = paginate
      }

      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/estoque-mensal',
        queryParams
      })) as { dados: IEstoqueMensal[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async producaoDeLeite(
    periodo?: { de: string; ate: string; periodo: 'Meses' | 'Dias' | string },
    paginate?: { offset: number; limit: number }
  ): Promise<[{ dados: IRelatorioProducaoDeLeite[] }, Error?]> {
    try {
      let queryParams: QueryParamsmModel = {}

      if (periodo) {
        queryParams = {
          de: periodo.de,
          ate: periodo.ate,
          periodo: periodo.periodo
        }
      }

      if (paginate) {
        queryParams.paginate = paginate
      }

      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/producao-de-leite',
        queryParams
      })) as { dados: IRelatorioProducaoDeLeite[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getProducaoLeitePorVaca(opcoes: {
    lote?: string
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: IRelatorioProducaoDeLeitePorVaca[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/producao-de-leite-por-vaca',
        filtros: opcoes.filtros
      })) as { dados: IRelatorioProducaoDeLeitePorVaca[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async mapaSanitario(
    periodo?: { de: string; ate: string },
    paginate?: { offset: number; limit: number }
  ): Promise<[{ dados: IMapaSanitario[] }, Error?]> {
    try {
      let queryParams: QueryParamsmModel = {}

      if (periodo) {
        queryParams = {
          de: periodo.de,
          ate: periodo.ate
        }
      }

      if (paginate) {
        queryParams.paginate = paginate
      }

      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/mapa-sanitario',
        queryParams
      })) as { dados: IMapaSanitario[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async desembolsoCabecaMes(
    periodo?: { de: string; ate: string },
    paginate?: { offset: number; limit: number }
  ): Promise<[{
    dados: IDesembolsoCabecaMes[]
    totais: {
      desembolsoMedio: number
      rebanhoMedio: number
      desembolsoTotal: number
      desembolsoCabecaMedio: number
    }
  }, Error?]> {
    try {
      let queryParams: QueryParamsmModel = {}

      if (periodo) {
        queryParams = {
          de: periodo.de,
          ate: periodo.ate
        }
      }

      if (paginate) {
        queryParams.paginate = paginate
      }

      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/desembolso-cabeca-mes',
        queryParams
      })) as {
        dados: IDesembolsoCabecaMes[]; 
        totais: {
          desembolsoMedio: number
          rebanhoMedio: number
          desembolsoTotal: number
          desembolsoCabecaMedio: number
        }
      }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async movimentacoes(
    periodo?: { de: string; ate: string },
    paginate?: { offset: number; limit: number }
  ): Promise<
    [
      ResponseMovimentacoes,
      Error?
    ]
  > {
    let url = 'relatorios/movimentacoes'
    let queryParams: QueryParamsmModel = {}

    if (periodo) {
      queryParams = {
        de: periodo.de,
        ate: periodo.ate
      }
    }

    if (paginate) {
      queryParams.paginate = paginate
    }

    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url,
        queryParams
      })) as ResponseMovimentacoes
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async mortalidade(
    periodo?: {
      de: string
      ate: string
    },
    paginate?: { offset: number; limit: number }
  ): Promise<[{ dados: IMortalidade[]; totais: ITotaisMortalidade; meses: string[] }, Error?]> {
    try {
      let queryParams: QueryParamsmModel = {}

      if (periodo) {
        queryParams = {
          de: periodo.de,
          ate: periodo.ate
        }
      }

      if (paginate) {
        queryParams.paginate = paginate
      }

      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/mortalidade',
        queryParams
      })) as { dados: IMortalidade[]; totais: ITotaisMortalidade; meses: string[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async chuvas(
    periodo?: { de: string; ate: string },
    paginate?: { offset: number; limit: number }
  ): Promise<[{ dados: IChuvas[]; totais: ITotalChuvas }, Error?]> {
    try {
      let queryParams: QueryParamsmModel = {}

      if (periodo) {
        queryParams = {
          de: periodo.de,
          ate: periodo.ate
        }
      }

      if (paginate) {
        queryParams.paginate = paginate
      }

      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/chuvas',
        queryParams
      })) as { dados: IChuvas[]; totais: ITotalChuvas }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async todasChuvas(filtros?: NovoQueryParamsModel): Promise<[ResponseTodasChuvas, Error?]> {
    try {
      let url = 'relatorios/todas-chuvas'
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = await this.apiCtrl.getComPrefixoPropriedade({
        url,
        filtros
      }) as ResponseTodasChuvas
      if (response) return [response, null]
    } catch (error) {
      return [null, error]
    }
  }

  async pesagens(opcoes: {
    de: string
    ate: string
    lote?: string
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: IRelatorioPesagem[]; totais: ITotaisRelatorioPesagem }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/pesagens/geral',
        filtros: opcoes.filtros,
        queryParams: opcoes
      })) as { dados: IRelatorioPesagem[]; totais: ITotaisRelatorioPesagem }

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async desempenhoNutricional(opcoes: {
    de: string
    ate: string
    lotes?: string[]
    estoques?: string[]
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: IRelatorioDesempenhoNutricional[]; totais: ITotaisRelatorioPesagem }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'relatorios/nutricional/desempenho',
        body: {
          de: opcoes.de,
          ate: opcoes.ate,
          lotes: opcoes.lotes,
          estoques: opcoes.estoques,
        },
        filtros: opcoes.filtros,
        queryParams: opcoes
      })) as { dados: IRelatorioDesempenhoNutricional[]; totais: ITotaisRelatorioPesagem }

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async consumoNutricional(opcoes: {
    de: string
    ate: string
    lotes?: string[]
    estoques?: string[]
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: IRelatorioConsumoNutricional[]; totais: ITotaisRelatorioPesagem }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'relatorios/nutricional/consumo',
        body: {
          de: opcoes.de,
          ate: opcoes.ate,
          lotes: opcoes.lotes,
          estoques: opcoes.estoques,
        },
        filtros: opcoes.filtros,
        queryParams: opcoes
      })) as { dados: IRelatorioConsumoNutricional[]; totais: ITotaisRelatorioPesagem }

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async custosELucratividade(hashs?: NovoQueryParamsModel): Promise<[{
    dados: IRelatorioCustoAnimais[], totais: {
    custo_compra: number
    custo_nutricional: number
    custo_sanitario: number
    custo_atribuido: number
    custo_total: number
    machos: number
    femeas: number
  } }, Error?]> {
    try {
      const filtros = this.apiCtrl.formatarFiltros({filtros: hashs})

      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'relatorios/custos-e-lucratividade',
        body: { filtros }
      })) as { dados: IRelatorioCustoAnimais[], totais: {
        custo_compra: number
        custo_nutricional: number
        custo_sanitario: number
        custo_atribuido: number
        custo_total: number
        machos: number
        femeas: number
      } }

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async custoDaArrobaProduzida(params?: NovoQueryParamsModel): Promise<[{
    dados: RelatorioCustoDaArroba[],
    totais: {
      custo_total: number
      custo_kg: number
      custo_arroba: number
      kgs_produzidos: number
      arrobas_produzidas: number
    }
  }, Error?]> {
    try {
      const filtros = this.apiCtrl.formatarFiltros({filtros: params})

      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'relatorios/custo-arroba-produzida',
        body: { filtros }
      })) as {
        dados: RelatorioCustoDaArroba[],
        totais: {
          custo_total: number
          custo_kg: number
          custo_arroba: number
          kgs_produzidos: number
          arrobas_produzidas: number
        }
      }

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async arrobasProduzidas(
    periodo?: { de: string; ate: string },
    paginate?: { offset: number; limit: number }
  ): Promise<
    [
      {
        dados: IRelatorioArrobasProduzidas[]
        arrobas_produzidas: number
        kgs_produzidos: number
        compras: number
        vendas: number
        transferencias_entrada: number
        transferencias_saida: number
        peso_inicial: number
        peso_final: number
      },
      Error?
    ]
  > {
    let url = 'relatorios/arrobas'
    let queryParams: QueryParamsmModel = {}

    if (periodo) {
      queryParams = {
        de: periodo.de,
        ate: periodo.ate
      }
    }

    if (paginate) {
      queryParams.paginate = paginate
    }

    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url,
        queryParams
      })) as {
        dados: IRelatorioArrobasProduzidas[]
        arrobas_produzidas: number
        kgs_produzidos: number
        compras: number
        vendas: number
        transferencias_entrada: number
        transferencias_saida: number
        peso_inicial: number
        peso_final: number
      }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async custoDoAnimal(
    filtros: NovoQueryParamsModel,
    paginate?: { offset: number; limit: number }
  ): Promise<
    [
      {
        dados: {
          hash_compra: string
          valor_compra: number
          hash_venda: string
          valor_venda: number
          custo_nutricao: number
          custo_sanitario: number
          nutricional: ICustosAnimal[]
          sanitario: ICustosAnimal[]
          atribuicao: ICustosAnimal[]
        }
      },
      Error?
    ]
  > {
    let url = 'relatorios/custos-do-animal'

    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url,
        filtros
      })) as {
        dados: {
          hash_compra: string
          valor_compra: number
          hash_venda: string
          valor_venda: number
          custo_nutricao: number
          custo_sanitario: number
          nutricional: ICustosAnimal[]
          sanitario: ICustosAnimal[]
          atribuicao: ICustosAnimal[]
        }
      }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async evolucaoDePeso(
    periodo?: {
      de: string
      ate: string
      lote: string
    },
    paginate?: { offset: number; limit: number }
  ): Promise<[{ dados: IGmdGlobalMedio[]; totais: ITotaisGmdGlobalMedio }, Error?]> {
    try {
      let queryParams: QueryParamsmModel = {}

      if (periodo) {
        queryParams = {
          de: periodo.de,
          ate: periodo.ate
        }
      }

      if (periodo.lote) {
        queryParams.lote = encodeURIComponent(periodo.lote)
      }

      if (paginate) {
        queryParams.paginate = paginate
      }

      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/ganho-de-peso',
        queryParams
      })) as { dados: IGmdGlobalMedio[]; totais: ITotaisGmdGlobalMedio }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async previsaoPartos(opcoes: {
    lote?: string
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: RelatorioPrevisaoPartos[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/previsao-de-parto',
        filtros: opcoes.filtros
      })) as { dados: RelatorioPrevisaoPartos[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async manutencaoGenealogia(opcoes: {
    lote?: string
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: ManutencaoGenealogiaENascimento[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/manutencao/genealogia',
        filtros: opcoes.filtros
      })) as { dados: ManutencaoGenealogiaENascimento[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async previsaoDeDesmames(opcoes: {
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: RelatorioPrevisaoPartos[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/previsao-de-desmames',
        filtros: opcoes.filtros
      })) as { dados: RelatorioPrevisaoPartos[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async financeiroFluxoDeCaixa(opcoes: {
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: RelatorioFluxoDeCaixa[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/financeiro/fluxo-de-caixa',
        filtros: opcoes.filtros
      })) as { dados: RelatorioFluxoDeCaixa[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async financeiroDRE(opcoes: {
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: RelatorioFluxoDeCaixa[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/financeiro/dre',
        filtros: opcoes.filtros
      })) as { dados: RelatorioFluxoDeCaixa[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async financeiroPorCategorias(opcoes: {
    filtros: NovoQueryParamsModel
  }): Promise<[{ dados: RelatorioFluxoDeCaixa[], lancamentos: IFinancasTransacao[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/financeiro/analise',
        filtros: opcoes.filtros
      })) as { dados: RelatorioFluxoDeCaixa[], lancamentos: IFinancasTransacao[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async partos(opcoes: { filtros: NovoQueryParamsModel }): Promise<[RelatorioPartos[], Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/partos',
        filtros: opcoes.filtros
      })) as RelatorioPartos[]
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async desmames(opcoes: { filtros: NovoQueryParamsModel }): Promise<[RelatorioDesmames[], Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/desmames',
        filtros: opcoes.filtros
      })) as RelatorioDesmames[]
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async abortos(opcoes: { filtros: NovoQueryParamsModel }): Promise<[RelatorioAbortos[], Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/abortos',
        filtros: opcoes.filtros
      })) as RelatorioAbortos[]
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async vacasAptasPosParto(opcoes: {
    filtros: NovoQueryParamsModel
  }): Promise<[{ animais: RelatorioVacasAptasPosParto[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/vacas-aptas-pos-parto',
        filtros: opcoes.filtros
      })) as { animais: RelatorioVacasAptasPosParto[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async vacasInseminadasNaoTocadas(opcoes: {
    filtros: NovoQueryParamsModel
  }): Promise<[{ animais: RelatorioVacasNaoTocadas[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/vacas-inseminadas-nao-tocadas',
        filtros: opcoes.filtros
      })) as { animais: RelatorioVacasNaoTocadas[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async intervaloEntrePartos(opcoes: { filtros: NovoQueryParamsModel }): Promise<[{ partos: RelatorioIntervaloEntrePartos[]} , Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/intervalo-entre-partos',
        filtros: opcoes.filtros
      })) as { partos: RelatorioIntervaloEntrePartos[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getPerdas(opcoes: { filtros: NovoQueryParamsModel }): Promise<[{ dados: RelatorioPerda[], totais: ITotaisPerda, meses: string[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'relatorios/perda',
        filtros: opcoes.filtros
      })) as { dados: RelatorioPerda[], totais: ITotaisPerda, meses: string[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async todosReprodutivos(opcoes: {
    de: string
    ate: string
    lotes?: string[]
    filtros: NovoQueryParamsModel
  }): Promise<[{ manejos: RelatorioTodosReprodutivos[]} , Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'relatorios/todos-reprodutivos',
        body: {
          de: opcoes.de,
          ate: opcoes.ate,
          lotes: opcoes.lotes
        },
        filtros: opcoes.filtros,
        queryParams: opcoes
      })) as { manejos: RelatorioTodosReprodutivos[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { MenuController, ModalController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import { environment } from 'src/environments/environment'
import { NavegacaoService } from 'src/app/services/navegacao.service'
import { PermissoesUsuarioService } from 'src/app/services/permissoes-usuario.service'
import { PlanosAssinaturaService } from 'src/app/services/plano-assinatura.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { UsuariosService } from 'src/app/services/usuarios.service'
import { IPropriedade } from 'src/app/utils/interfaces/propriedades.interface'
import { InfoLimitePlano, StatusPermissaoUsuario } from 'src/app/utils/interfaces/Usuarios.interface'
import { ModalConfiguracoesPage } from './modal-configuracoes/modal-configuracoes.page'
import { ModalListaPropriedadesPage } from './modal-lista-propriedades/modal-lista-propriedades.page'
import { ModalMenuPesquisaPage } from './modal-menu-pesquisa/modal-menu-pesquisa.page'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'

interface AppPages {
  title: string
  url: string
  icon?: string
  type?: string
  sub?: boolean
  esconder?: boolean
  class?: string
  beta?: boolean
}

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.page.html',
  styleUrls: ['./menu-lateral.page.scss']
})
export class MenuLateralPage implements OnInit, OnDestroy {
  APP_VERSION = environment.appVersion

  public isAtualizandoCartaoUsuario = false
  public appPagesOriginal: AppPages[] = [
    { title: 'Propriedade', url: '/separator', type: 'separator' }, // , icon: 'home'
    { title: 'Visão Geral', url: '', icon: 'home-sharp' },
    { title: 'Calendário', url: '/calendario', icon: 'calendar-clear' },
    { title: 'Pluviometria', url: '/pluviometria', icon: 'rainy' },
    { title: 'Áreas', url: '/areas', icon: 'location-sharp' },
    { title: 'Rebanho', url: '/animais', icon: 'rebanho' },
    { title: 'Manejos e Registros', url: '/manejos', icon: 'clipboard' },

    { title: 'Gerenciar', url: '/separator', type: 'separator' }, //  icon: 'book',
    { title: 'Financeiro', url: '/financeiro', icon: 'wallet' },
    { title: 'Relatórios', url: '/relatorios', icon: 'stats-chart' },
    { title: 'Estoque', url: '/estoque', icon: 'file-tray-stacked' },
    { title: 'Aprovações', url: '/aprovacoes', icon: 'checkbox' },
    { title: 'Consultor Virtual', url: '/consultor', beta: true, icon: 'chatbox-ellipses' },

    { title: 'Extra', url: '/separator', type: 'separator' }, // , icon: 'apps'
    { title: 'Educação', url: '/educacao', icon: 'logo-youtube' },
    { title: 'Usuários e Fertili Campo', url: '/usuarios', icon: 'people' },

    // { title: 'Tutoriais', url: '/tutoriais', esconder: false }
  ]
  public appPages: AppPages[]
  public informacoesPropriedade = {
    proprietarioNome: null,
    proprietarioRole: null,
    qtdAnimaisPlano: null,
    qtdAnimaisPropriedade: null,
    percentualAnimaisPropriedade: null
  }

  public informacoesDoPlanoUsuario: InfoLimitePlano
  public rxStatePropriedadeSelecionada = null
  public rxStatusPermissao = null

  private rxAprovacoes = null

  public rxStateAtualizarCartaoUsuario = null

  constructor(
    private menuCtrl: MenuController,
    public propriedadesCtrl: PropriedadesService,
    private router: Router,
    public navegacaoCtrl: NavegacaoService,
    public planoCtrl: PlanosAssinaturaService,
    public permissoesUsuarioCtrl: PermissoesUsuarioService,
    private modalCtrl: ModalController,
    public usuarioCtrl: UsuariosService,
    public planoBasicoCtrl: PlanoBasicoService
  ) {}

  async ngOnInit(): Promise<void> {
    this.appPages = JSON.parse(JSON.stringify(this.appPagesOriginal))

    this.observerAnimaisCadastrados()

    this.rxStatusPermissao = VastaRX.getState('statusPermissao', (emit: StatusPermissaoUsuario) => {
      if (emit) {
        this.verificarPermissoesMenu()

        if (this.rxStatusPermissao) {
          VastaRX.unListen(this.rxStatusPermissao)
        }
      }
    })

    if (this.propriedadesCtrl?.propriedadeSelecionada?.propriedade_exemplo) {
      await this.propriedadesCtrl.selecionarPropriedadeExemplo()
    }

    this.getInformacoesDoPlanoUsuarioDaPropriedade()
    this.observerPropriedadeSelecionada()

    this.rxAprovacoes = VastaRX.getState('firestore_aprovacoes_update', (value) => {
      const pageItemAprovacao = this.appPages.find((item) => item.title === 'Aprovações')
      if (value && value?.length) {
        pageItemAprovacao.class = 'notification'
      }
      if (value == 'limpar') {
        delete pageItemAprovacao.class
      }
    })

    this.atualizarCartaoDeUusario()
  }

  ngOnDestroy(): void {
    VastaRX.unListen(this.rxAprovacoes)
    if (this.rxStatePropriedadeSelecionada) {
      VastaRX.unListen(this.rxStatePropriedadeSelecionada)
    }
  }

  getInformacoesDoPlanoUsuarioDaPropriedade(propriedadeSelecionada?: IPropriedade): void {
    const propriedade = propriedadeSelecionada || this.propriedadesCtrl.propriedadeSelecionada
    const limiteDePlanoPorPropriedade = this.permissoesUsuarioCtrl.limiteDePlanoPorPropriedade

    if (limiteDePlanoPorPropriedade && propriedade) {
      this.informacoesDoPlanoUsuario = limiteDePlanoPorPropriedade[propriedade.id]
    }
  }

  async atualizarCartaoDeUusario(): Promise<void> {
    try {
      this.isAtualizandoCartaoUsuario = true
      await this.permissoesUsuarioCtrl.atualizarInformacoesDePermissaoDoUsuario()
      this.getInformacoesDoPlanoUsuarioDaPropriedade()
    } catch(error) {
      console.error(error)
    } finally {
      this.isAtualizandoCartaoUsuario = false
    }
  }

  /**
   * Sempre que cadastrar algum animal, o cartão de usuário deve atualizar
   */
  observerAnimaisCadastrados(): void {
    this.rxStateAtualizarCartaoUsuario = VastaRX.getState('atualizar-cartao-usuario', async (isAtualizar: boolean) => {
      if(isAtualizar) {
        await this.atualizarCartaoDeUusario()
      }
    })
  }

  observerPropriedadeSelecionada(): void {
    this.rxStatePropriedadeSelecionada = VastaRX.getState(
      'propriedade_selecionada',
      async (propriedadeSelecionada: IPropriedade) => {
        if (propriedadeSelecionada) {
          this.getInformacoesDoPlanoUsuarioDaPropriedade(propriedadeSelecionada)
          this.verificarPermissoesMenu()
          await this.permissoesUsuarioCtrl.verificarPermissoes()
        }
      }
    )
  }

  async openModalConfiguracoes(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalConfiguracoesPage,
      cssClass: 'custom-modal-config'
    })

    await modal.present()
  }

  async openModalListaPropriedades(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaPropriedadesPage,
      cssClass: 'custom-modal-seletor'
    })

    await modal.present()
  }

  async openModalPesquisa(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalMenuPesquisaPage,
      cssClass: 'custom-modal-menu-pesquisa'
    })
    await modal.present()
  }

  navegacaoMenu(rota: string): void {
    this.menuClose()
    this.router.navigateByUrl(rota)
  }

  async menuClose(url = '', ev?: Event): Promise<void> {
    if (!this.propriedadesCtrl.isPropriedadeExemploAtivada) {
      const { acessoAoItemBloqueado } = await this.planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('abasMenuLateral', url, ev)
      if (acessoAoItemBloqueado) return
    }

    this.navegacaoCtrl.set([url])
    this.menuCtrl.close()
  }

  async openLinkWhatsapp(): Promise<void> {
    this.planoCtrl.entrarEmContatoPorWhatsapp()
  }

  verificarRotaAtiva(url: string): boolean {
    if ((url === '' && this.router.url === '/') || this.router.url === url) {
      return true
    }
    return false
  }

  verificarPermissoesMenu(): void {
    const { permissoesDoUsuarioNaPropriedade } = this.permissoesUsuarioCtrl
    const isRestricoes = this.propriedadesCtrl?.propriedadeSelecionada?.permissoes
    const isPropriedadeCorte = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.tipo_producao === 'corte'

    if (isRestricoes) {
      console.warn({ isRestricoes })
      console.warn({ permissoesDoUsuarioNaPropriedade })
      // adicioanr classes de bloqueio ao <body>
    }

    this.appPages.forEach((page) => {
      let pageUrl = page.url.replace('/', '')
      let isUsuarioTemPermissao = permissoesDoUsuarioNaPropriedade ? permissoesDoUsuarioNaPropriedade[pageUrl] : null

      if(pageUrl === 'usuarios' && !isUsuarioTemPermissao) {
        isUsuarioTemPermissao = permissoesDoUsuarioNaPropriedade?.['usuarios_360debolso']
      }
      
      if (pageUrl === 'pluviometria' && !isUsuarioTemPermissao) {
        isUsuarioTemPermissao = permissoesDoUsuarioNaPropriedade?.['areas']
      }

      page.esconder = false
      if (isRestricoes && pageUrl && page?.type !== 'separator' && !isUsuarioTemPermissao) {
        page.esconder = true
      }
      if (isPropriedadeCorte && page?.title == 'Controle Leiteiro') {
        page.esconder = true
      }
    })
  }

  labelPlano(slug: string | number): string {
    if (!isNaN(Number(slug))) {
      return 'Premium'
    } 
    if(slug == 'basico') {
      return 'Controle'
    } 
    if(slug == 'trial') {
      return 'Premium 7 Dias'
    }
    if(slug == 'essencial-mensal') {
      return 'Essencial Mensal'
    }
    if(slug == 'essencial-trimestral') {
      return 'Essencial Trimestral'
    }
    if(slug == 'essencial-anual') {
      return 'Essencial Anual'
    }
    if(slug == 'essencial-bianual') {
      return 'Essencial Bianual'
    }
    if(slug == 'essencial-trianual') {
      return 'Essencial Trianual'
    }
    if(slug == 'avancado-mensal') {
      return 'Avançado Mensal'
    }
    if(slug == 'avancado-trimestral') {
      return 'Avançado Trimestral'
    }
    if(slug == 'avancado-anual') {
      return 'Avançado Anual'
    }
    if(slug == 'avancado-bianual') {
      return 'Avançado Bianual'
    }
    if(slug == 'avancado-trianual') {
      return 'Avançado Trianual'
    }
    if(slug == 'elite-mensal') {
      return 'Elite Mensal'
    }
    if(slug == 'elite-trimestral') {
      return 'Elite Trimestral'
    }
    if(slug == 'elite-anual') {
      return 'Elite Anual'
    }
    if(slug == 'elite-bianual') {
      return 'Elite Bianual'
    }
    if(slug == 'elite-trianual') {
      return 'Elite Trianual'
    }
  }

  async openModalPlanoBasico(event: Event): Promise<void> {
    if (this.usuarioCtrl?.usuarioCache?.trial_iniciado === 1) {
      this.planoBasicoCtrl.sePlanoAtivoForBasicoAbrirModalDeAssinatura()
    } else {
      this.planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('cta', 'menu_lateral', event)
    }
  }
}

import * as moment from 'moment'
import { opcoesFiltros } from './opcoes-filtros'

const tabelaFormat = {
  dinheiro: (valor: string): string => {
    const numero = Number(valor || 0)
    if (Number.isNaN(numero)) return ''
    let parts = numero.toFixed(2).split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return 'R$ ' + parts.join(',')
  },
  int: (valor: string): string => {
    const numero = Number(valor || 0)
    return numero.toFixed(0)
  },
  data: (valor: string): string => {
    valor = valor?.replace('.000Z', '')
    valor = valor?.replace('T', ' ')
    if (!valor) {
      return ''
    }
    if (valor?.length > 10) {
      return moment.utc(valor, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YYYY')
    } else {
      return moment(valor, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }
  },
  dataHora: (valor: string): string => {
    valor = valor?.replace('.000Z', '')
    valor = valor?.replace('T', ' ')
    if (!valor) {
      return ''
    }
    if (valor?.length > 10) {
      return moment.utc(valor, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YYYY [às] HH:mm')
    } else {
      return moment(valor, 'YYYY-MM-DD').format('DD/MM/YYYY [às] HH:mm')
    }
  },
  hora: (valor: string): string => {
    if (valor.length === 4) {
      return `${valor.slice(0, 2)}:${valor.slice(2)}`
    } else {
      return ''
    }
  },
  mes: (valor: string): string => {
    valor = valor.replace('.000Z', '')
    valor = valor.replace('T', ' ')
    if (valor.length > 10) {
      return moment.utc(valor, 'YYYY-MM-DD HH:mm:ss').local().format('MM/YYYY')
    } else {
      return moment(valor, 'YYYY-MM-DD').format('MM/YYYY')
    }
  },
  peso: (valor: string): string => {
    const numero = Number(valor || 0)
    if (Number.isNaN(numero)) return ''
    let parts = numero.toFixed(1).split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    let formatado = parts.join(',')
    return formatado.replace(',0', '')
  },
  gmd: (valor: string): string => {
    const numero = Number(valor || 0)
    if (Number.isNaN(numero)) return ''
    let parts = numero.toFixed(3).split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    let formatado = parts.join(',')
    return formatado.replace(',000', '')
  },
  labelsRaca: (valor: string): string => {
    try {
      if (!valor) return ''
      const list = (valor || '').split(',')
      return list
        .map((r) => {
          return opcoesFiltros.labelsRaca[r] || r
        })
        .join(', ')
        .replace(/2,/g, '2')
        .replace(/4,/g, '4')
        .replace(/6,/g, '6')
        .replace(/8,/g, '8')
        .replace(', P.O.', ' P.O.')
    } catch (error) {
      console.error(error)
      return ''
    }
  },
  labelsCategoriasFinancas: (valor: string): string => {
    if (valor.includes('transferencia-')) return '-'
    return opcoesFiltros.labelsCategoriasFinancas[valor] || valor
  },
  labelsExposicao: (valor: string): string => {
    return opcoesFiltros.labelsExposicao[valor] || valor
  },
  labelsExposicaoTipo: (valor: string): string => {
    return opcoesFiltros.labelsExposicaoTipo[valor] || valor
  },
  labelsExposicaoDG: (valor: string): string => {
    return opcoesFiltros.labelsExposicaoDG[valor] || valor
  },
  labelsExposicaoCio: (valor: string): string => {
    return opcoesFiltros.labelsExposicaoCio[valor] || valor
  },
  labelsManejoRealizado: (valor: string): string => {
    return opcoesFiltros.labelsManejoRealizado[valor] || valor
  },
  labelsCausaMorte: (valor: string): string => {
    return opcoesFiltros.labelsCausaMorte[valor] || valor
  },
  labelsCategoriaAnimal: (valor: string): string => {
    return opcoesFiltros.labelsCategoriaAnimal[valor] || valor
  },
  labelsCategoria: (valor: string): string => {
    return opcoesFiltros.labelsCategoria[valor] || valor
  },
  labelsManejosReprodutivos: (valor: string): string => {
    return opcoesFiltros.labelsManejosReprodutivos[valor] || valor
  },
  labelsManejosGerais: (valor: string): string => {
    return opcoesFiltros.labelsManejosGerais[valor] || valor
  },
  labelsExposicoes: (valor: string): string => {
    return opcoesFiltros.labelsExposicoes[valor] || valor
  },
  labelsExposicaoInseminacaoTipo: (valor: string): string => {
    return opcoesFiltros.labelsExposicaoInseminacaoTipo[valor] || valor
  },
  labelsManejosSanitarios: (valor: string): string => {
    return opcoesFiltros.labelsManejosSanitarios[valor] || valor
  },
  labelsTipoDeCapimArea: (valor: string): string => {
    return opcoesFiltros.labelsTipoDeCapimArea[valor] || valor
  },
  labelsTipoArea: (valor: string): string => {
    return opcoesFiltros.labelsTipoDeArea[valor] || valor
  },
  labelsEstoqueLancamentoMovimentacao: (valor: string): string => {
    return opcoesFiltros.labelsEstoqueLancamentoMovimentacao[valor] || valor
  },
  labelsTiposFinancas: (valor: string): string => {
    return opcoesFiltros.labelsTiposFinancas[valor] || valor
  },
  labelsTiposContas: (valor: string): string => {
    return opcoesFiltros.labelsTiposContas[valor] || valor
  },
  labelsFormasDePagamentoFinancas: (valor: string): string => {
    return opcoesFiltros.labelsFormasDePagamentoFinancas[valor] || valor
  },
  labelsEstoqueBiologicoLancamentoTipo: (valor: string): string => {
    return opcoesFiltros.labelsEstoqueBiologicoLancamentoTipo[valor] || valor
  },
  labelsEstoqueNutricionalLancamentoTipo: (valor: string): string => {
    return opcoesFiltros.labelsEstoqueNutricionalLancamentoTipo[valor] || valor
  },
  labelsEstoqueNutricionalTipo: (valor: string): string => {
    return opcoesFiltros.labelsEstoqueNutricionalTipo[valor] || valor
  },
  labelsEstoqueFarmaciaTipo: (valor: string): string => {
    return opcoesFiltros.labelsEstoqueFarmaciaTipo[valor] || valor
  },
  labelsEstoqueFerramentasTipo: (valor: string): string => {
    return opcoesFiltros.labelsEstoqueFerramentasTipo[valor] || valor
  },
  labelsCalendarioEvento: (valor: string): string => {
    return opcoesFiltros.labelsCalendarioEvento[valor] || valor
  },
  labelsSituacao: (valor: string): string => {
    return opcoesFiltros.labelsSituacao[valor] || valor
  },
  labelsSituacaoReprodutiva: (valor: string): string => {
    return opcoesFiltros.labelsSituacaoReprodutiva[valor] || valor
  },
  labelsSituacaoSanitaria: (valor: string): string => {
    return opcoesFiltros.labelsSituacaoSanitaria[valor] || valor
  },
  labelsPelagem: (valor: string): string => {
    return valor
  },
  tipoDeManejoNutricional: (valor: string): string => {
    return opcoesFiltros.tipoDeManejoNutricional[valor] || valor
  },
  tipoDeManejoCustoAnimal: (valor: string): string => {
    return opcoesFiltros.tipoDeManejoCustoAnimal[valor] || valor
  },
  labelsSistemaCriacao: (valor: string): string => {
    return opcoesFiltros.labelsSistemaCriacao[valor] || valor
  },
  labelsAptidao: (valor: string): string => {
    return opcoesFiltros.labelsAptidao[valor] || valor
  },
  labelsFinalidade: (valor: string): string => {
    return opcoesFiltros.labelsFinalidade[valor] || valor
  },
  labelsCriaAoPe: (valor: number): string => {
    return opcoesFiltros.labelsCriaAoPe[valor] || valor
  },
  labelsPessoa: (valor: string): string => {
    return opcoesFiltros.labelsPessoa[valor] || valor
  },
  labelsTipoSaida: (valor: string): string => {
    return opcoesFiltros.labelsTipoSaida[valor] || valor
  },
  labelsSexo: (valor: string): string => {
    return opcoesFiltros.labelsSexo[valor] || valor
  },
  labelsParto: (valor: number): string => {
    return opcoesFiltros.labelsParto[valor] || valor
  },
  labelsLancamentoTipo: (valor: string): string => {
    return opcoesFiltros.labelsLancamentoTipo[valor] || valor
  },
  labelsRealizado: (valor: string): string => {
    return opcoesFiltros.labelsRealizado[valor] || valor
  },
  labelsRemoverAnimaisDoRebanho: (valor: string): string => {
    return opcoesFiltros.labelsRemoverAnimaisDoRebanho[valor] || valor
  },
  labelsRelatorioTodosReprodutivos: (valor: string): string => {
    return opcoesFiltros.labelsRelatorioTodosReprodutivos[valor] || valor
  },
  labelsListaTodosManejos: (valor: string): string => {
    return opcoesFiltros.labelsListaTodosManejos[valor] || valor
  },
  labelsTiposExposicao: (valor: string): string => {
    return opcoesFiltros.labelsTiposExposicao[valor] || valor
  },
  labelsSituacaoLote: (valor: string): string => {
    return opcoesFiltros.labelsSituacaoLote[valor] || valor
  }
}

export { tabelaFormat }

<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ tituloPagina }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal(false)">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <header-tabs
    *ngIf="tipoTransacao !== 'transferencia'"
    [tabs]="['Geral', 'Atribuir Valores']"
    [(tabActive)]="abaAtiva"
  ></header-tabs>
</ion-header>

<!-- class="ion-padding" -->
<ion-content [class.ion-padding-top]="tipoTransacao !== 'transferencia'">
  <div [hidden]="abaAtiva != 'Geral'">
    <vasta-input
      label="Título"
      type="text"
      placeholder="Título do Lançamento"
      [obrigatorio]="true"
      [(value)]="lancamento.titulo"
    ></vasta-input>

    <ion-row>
      <ion-col size="6" style="padding: 0; padding-right: 10px" *ngIf="tipoTransacao === 'transferencia'">
        <vasta-input
          label="Conta a Debitar"
          [value]="contasPorHash[lancamento.hash_conta_transferencia]?.titulo"
          type="button"
          [obrigatorio]="true"
          placeholder="Selecionar"
          (buttonEvent)="abrirListaDeContas('debitar')"
        ></vasta-input>
      </ion-col>

      <ion-col style="padding: 0">
        <vasta-input
          [label]="tipoTransacao === 'transferencia' ? 'Conta a Creditar' : 'Conta'"
          [value]="contasPorHash[lancamento.hash_conta]?.titulo"
          type="button"
          [obrigatorio]="true"
          [disabled]="carregandoContas"
          [placeholder]="tipoTransacao === 'transferencia' ? 'Selecionar' : 'Sem Conta'"
          (buttonEvent)="abrirListaDeContas('creditar')"
          (clear)="lancamento.hash_conta = ''"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="tipoTransacao !== 'transferencia'">
      <ion-col size="6" style="padding: 0; padding-right: 10px">
        <vasta-input
          label="Categoria"
          type="button"
          [disabled]="!!categoriaProp"
          [obrigatorio]="true"
          placeholder="Selecionar"
          (buttonEvent)="abrirPopoverListaCategorias($event)"
          [value]="categoriasLabels[lancamento.meta_categorias] || categoriasLabels[tipoTransacao + '-' + lancamento.meta_categorias] || lancamento.meta_categorias"
        ></vasta-input>
      </ion-col>

      <ion-col size="6" style="padding: 0">
        <vasta-input
          label="Subcategoria"
          type="button"
          [disabled]="!!categoriaProp"
          placeholder="Selecionar"
          (buttonEvent)="abrirModalListaSubcategorias()"
          [value]="lancamento.extra_subcategoria"
          (clear)="limparSubcategoria()"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col [size]="tipoTransacao !== 'transferencia' ? 6 : 12" style="padding-left: 0">
        <vasta-input
          label="Valor"
          type="text"
          placeholder="0,00"
          mask="separator.2"
          maskPrefix="R$ "
          [obrigatorio]="true"
          [(value)]="lancamento.valor"
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-right: 0" *ngIf="tipoTransacao !== 'transferencia'">
        <vasta-input
          [label]="tipoTransacao === 'entrada' ? 'Recebido de' : 'Pago a'"
          type="button"
          placeholder="Selecionar"
          (buttonEvent)="abrirModalListaPessoas()"
          [(value)]="pessoaLabelSelecionada"
          [value]="pessoasPorHash[lancamento.meta_pessoas]?.nome"
        >
        </vasta-input>
      </ion-col>
    </ion-row>

    <vasta-input
      [label]="tipoTransacao === 'entrada' ? 'Recebido' : 'Pago'"
      type="checkbox"
      [valueCheckbox]="lancamento.realizado == 1"
      (valueCheckboxChange)="setRealizado($event)"
    ></vasta-input>

    <ion-row>
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          label="Data de Vencimento"
          [obrigatorio]="true"
          type="date"
          [(value)]="lancamento.data"
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-right: 0">
        <vasta-input
          [label]="tipoTransacao === 'transferencia' ? 'Data da Transacao' : tipoTransacao === 'entrada'? 'Data de Recebimento' : 'Data do Pagamento'"
          type="date"
          [(value)]="lancamento.meta_data_pagamento"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <ng-template [ngIf]="lancamento.meta_categorias == 'receitas_pecuarias_venda_de_leite'">
      <vasta-input
        label="Quantidade em Litros"
        type="text"
        placeholder="0,00"
        mask="separator.2"
        maskSuffix=" kg"
        [obrigatorio]="true"
        [(value)]="lancamento.meta_litros_de_leite"
      ></vasta-input>
    </ng-template>

    <vasta-input
      label="Forma de Pagamento"
      type="select" placeholder="Selecionar"
      [opcoes]="opcoesPagamento"
      [(value)]="lancamento.meta_forma_pagamento"
    ></vasta-input>

    <vasta-input label="Descrição" type="textarea" [(value)]="lancamento.descricao"></vasta-input>

    <vasta-input
      *ngIf="tipoTransacao !== 'transferencia'"
      [disabled]="!!lancamento.hash"
      type="checkbox"
      label="Repetir Lançamento"
      [valueCheckbox]="!!(lancamento.meta_is_parcelamento == '1')"
      (valueCheckboxChange)="lancamento.meta_is_parcelamento == '1' ? lancamento.meta_is_parcelamento = '' : lancamento.meta_is_parcelamento = '1'"
    ></vasta-input>

    <p>&nbsp;</p>

    <ng-template [ngIf]="tipoTransacao !== 'transferencia' && lancamento.meta_is_parcelamento == '1' && !lancamento.hash">
      <!-- <h2>Repetição</h2> -->

      <ion-row>
        <ion-col size="6" style="padding-left: 0">
          <vasta-input
            label="Frequência"
            type="select"
            [obrigatorio]="true"
            placeholder="Selecionar"
            [opcoes]="opcoesFrequencia"
            [(value)]="lancamento.meta_repeticao"
          ></vasta-input>
        </ion-col>
        <ion-col size="6" style="padding-right: 0">
          <vasta-input
            label="Dividir Em"
            type="select"
            [obrigatorio]="true"
            placeholder="Selecionar"
            [opcoes]="opcoesDeDividir"
            [(value)]="lancamento.meta_qtd_parcelas"
          ></vasta-input>
        </ion-col>
      </ion-row>
    </ng-template>


    <vasta-input label="Número da Nota Fiscal" [(value)]="lancamento.meta_nf"></vasta-input>

    <button class="add-nf" *ngIf="listaPdf.length < 10">
      <enviar-pdf (pdfSrc)="getPdf($event)"></enviar-pdf>
      <span>Anexar PDF</span>
      <ion-icon name="cloud-upload"></ion-icon>
    </button>

    <h2 *ngIf="listaPdf.length > 2">PDFs Anexados</h2>
    <ion-list  *ngIf="listaPdf.length">
      <ion-item *ngFor="let pdf of listaPdf">
        <ion-label style="white-space: nowrap;overflow: hidden;direction: rtl;">{{pdf.name}}</ion-label>
        <ion-button fill="clear" (click)="viewPdf(pdf.url)">
          <ion-icon name="eye"></ion-icon>
        </ion-button>
        <ion-button fill="clear" (click)="removerPdf(pdf.chaveFinanceiro)">
          <ion-icon name="trash" color="danger"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>

    <p>&nbsp;</p>

    <ng-template [ngIf]="tipoTransacao !== 'transferencia'">
      <h2>Centro de Custo</h2>
      <cta-buttons class="ion-no-padding" style="margin-top: 0">
        <button (click)="showModalNovoCentroDeCusto()">
          <ion-icon name="add"></ion-icon>
          <span> Cadastrar Centro de Custo </span>
        </button>
      </cta-buttons>

      <ng-template let-centro ngFor [ngForOf]="pessoasCentroDeCustosValores">
        <div class="centro-de-custo-single">
          <vasta-input
            style="padding: 10px"
            type="checkbox"
            [label]="centro.nome"
            [valueCheckbox]="centro.marcado"
            (valueCheckboxChange)="selecionarPessoaParaCentroDeCusto($event, centro)"
          ></vasta-input>

          <div class="inputs-wrapper" *ngIf="qtdPessoasMarcadas > 1 && centro.marcado">
            <vasta-input
              label="Percentual"
              type="number"
              placeholder="0,00 %"
              maskSuffix=" %"
              mask="separator.5"
              [obrigatorio]="true"
              (valueChange)="calcularPercentualCusto($event, centro)"
              [(value)]="centro.percentual"
            >
            </vasta-input>
            <vasta-input
              label="Valor"
              type="number"
              [(value)]="centro.valor"
              maskPrefix="R$ "
              mask="separator.5"
              [obrigatorio]="true"
              placeholder="0,00"
              (valueChange)="calcularValorCusto($event, centro)"
            ></vasta-input>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
  <div [hidden]="abaAtiva != 'Atribuir Valores'">
    <vasta-input
      label="Atribuir Valores a"
      [value]="opcoesPopoverAtribuirCustos[lancamento.meta_atribuir_valor]?.texto"
      type="button"
      placeholder="Selecionar"
      (buttonEvent)="abrirPopoverAtribuirCustos($event)"
      (clear)="limparAtribuirValores()"
    ></vasta-input>

    <div *ngIf="lancamento.meta_atribuir_valor == 'animais'">
      <cta-buttons>
        <button (click)="selecionarAnimal()">
          <ion-icon slot="start" name="add-outline"></ion-icon>
          <span>Selecionar Animais</span>
        </button>
      </cta-buttons>

      <vasta-tabela
        *ngIf="animaisSelecionados?.length "
        [status]="carregamentoTabela"
        labelsId="colunas_animais"
        [campos]="animaisSelecionados"
        [config]="configTabelaAnimais"
        [optionsButtons]="optionsButtonsItensAnimais"
      ></vasta-tabela>
    </div>

    <div *ngIf="lancamento.meta_atribuir_valor == 'areas'">
      <cta-buttons>
        <button (click)="selecionarAreas()">
          <ion-icon slot="start" name="add-outline"></ion-icon>
          <span>Selecionar Áreas</span>
        </button>
      </cta-buttons>

      <vasta-tabela
        *ngIf="areasSelecionadas?.length "
        [status]="carregamentoTabela"
        [labels]="labelsAreas"
        [campos]="areasSelecionadas"
        [config]="configTabelaAreas"
        [optionsButtons]="optionsButtonsItensAreas"
      ></vasta-tabela>
    </div>
  </div>

  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
</ion-content>

<ion-footer class="ion-no-border">
  <div class="footer-wrapper">
    <div class="explicacao" *ngIf="isPorcentagemInvalida">
      <ion-icon name="alert-circle-outline"></ion-icon>
      <p>O total dos <b>percentuais</b> atribuídos aos centros de custos deve ser 100%.</p>
      <p>Total Atual: {{ somaPercentuais | number: '1.1-2' }}/100%</p>
    </div>
    <div class="footer-buttons-wrapper">
  <cta-buttons>
    <button
      class="primary permissao-escrita-financeiro"
      (click)="salvarLancamento()"
      [disabled]="isSalvandoTransacao || !isFormularioValido() || isPorcentagemInvalida"
    >
      <ion-icon slot="start" name="checkmark" *ngIf="!isSalvandoTransacao"></ion-icon>
      <ion-spinner name="dots" *ngIf="isSalvandoTransacao"></ion-spinner>
      <span>Salvar</span>
    </button>
  </cta-buttons>
  <cta-buttons *ngIf="lancamento.hash">
    <button
      class="danger-clear permissao-escrita-financeiro"
      (click)="confirmarExcluirCustoManejo(lancamento.hash)"
      [disabled]="isSalvandoTransacao"
    >
      <ion-icon slot="start" name="trash"></ion-icon>
      <span>Excluir</span>
    </button>
  </cta-buttons>
    </div>
  </div>
</ion-footer>

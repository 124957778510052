export const commonEnv = {
  appVersion: '2.7.4',
  jwtCode: 'TokenExpiredError'
}

export const versionamento: {
  versao: string
  descricao?: string
  data: string
  changelog: string[]
  mudancas_tecnicas?: string[]
}[] = [
  {
    versao: '2.7.4',
    descricao: `
      <p>Olá pecuarista!</p>
      <p>Nessa versão estamos adicionando a possibilidade de acessar o estoque de uma fazenda em todas as fazendas da mesma conta, sendo possível isolar os estoques de uma fazenda ou todas, caso necessário.</p>
      <p>Além disso, estamos trazendo uma nova fonte para números e datas em tabelas, campos e indicadores, para melhorar a legibilidade e a experiência de uso e comparação de dados.</p>
    `,
    data: '2024-03-04',
    changelog: [
      'Estoque: Nova opção da propriedade para isolar o estoque de uma fazenda',
      'Design: Nova fonte para números e datas em tabelas, campos e indicadores',
      'Estoques: Todos os estoques agora são acessíveis em todas as fazendas da mesma conta',
      'Arrobas Produzidas: Exibe corretamente valores de transferências de animais',
      'Filtros: Ajuste na exibição em dispositivos móveis',
      'Finanças: Nova opção de "Subcategoria" no lançamento financeiro',
      'Impressão: Ajuste de impressão em dispositivos Android',
      'Consumo Nutricional: Novos gráficos de Consumo Diário por Animal e Custo Diário por Animal',
      'Consumo Nutricional: Novos indicadores de Custo Diário Total por Animal e Consumo Diário Por Lote',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.7.3',
    descricao: `
      <p>Olá pecuarista! Nessa versão estamos trazendo a possibilidade de isolar o financeiro de uma ou todas as propriedades da mesma conta.</p>
      <p>Agora também é possível arquivar lotes, para exibir somente lotes ativos, sem perder o histórico de manejos e custos. Confira abaixo todas as novidades e melhorias dessa versão.</p>
    `,
    data: '2024-02-14',
    changelog: [
      'Custos e Lucratividade: Adiciona frete e comissões ao custo do animal',
      'Financeiro: Nova opção da propriedade para isolar o financeiro de uma fazenda',
      'Assinaturas: Planos vencidos agora só são bloqueados no dia após o vencimento, ao invés de serem bloqueados na data exata',
      'Aprovações: Correção na aprovação de registro de morte pelo Fertili Campo',
      'Desembolso por Cabeça/Mês: Ajuste na exibição de meses com valores zerados',
      'Registro de Morte: Novos tipos "Complicações no parto" e "Natimorto"',
      'Custos e Lucratividade: Nova coluna de situação atual do animal, exibindo inicialmente somente os animais vivos',
      'Lotes: Nova opção de arquivar lotes, uma maneira de ocultar lotes sem perder dados de manejos e custos',
      'Vendas: Corrigida a unidade de @ para kg na coluna "peso morto"',
      'Inseminação: Exibe nome da doadora do embrião no manejo de inseminação',
      'Pesagem para Compra/Venda: Ajuste no envio de todos os animais da pesagem para registro de compra/venda',
      'Aprovações: Permite aprovar animais sem nome e sem número',
      'Manejos: Ajuste dos contadores de manejos preenchidos de Observação, Pluviometria, Pastejo Rotacionado e Altura de Capim',
      'Notificações: Botão de listar notificações movido para Configurações',
      'Financeiro: Corrigido erro ao selecionar conta que acabou de ser cadastrada na compra e venda de animais',
      'Pluviometria: Ajuste do campo de hora da coleta',
    ],
    mudancas_tecnicas: [
      'Bloquear acessos de propriedades de terceiro, em que o terceiro tem plano basico',
      'Remove aprovações de plano básico',
      'Previne chamada múltipla de animais na tela de rebanho',
      'Não exibe chamada para demo para usuários secundários do trial',
    ]
  },
  {
    versao: '2.7.2',
    descricao: `
      <p>Olá pecuarista! Primeira atualização de fevereiro trazendo ajustes de usabilidade, e muitas novidades no <b>Fertili Campo</b>.</p>
      <p>O <b>Fertili Campo 2.0</b> trás mais controle sobre as opções de coleta disponíveis, campos avançados de diagnóstico de gestação e inseminação, registro de melhoramento genético e muito mais...</p>
    `,
    data: '2024-02-05',
    changelog: [
      'Aprovações: Novas aprovações dos novos manejos adicionados ao Fertili Campo',
      'Impressão: Readicionada a impressão em dispositivos móveis',
      'Financeiro: Importação de nota fiscal em .XML',
      'Manejos: Ajuste ao exportar lista de todos os manejos em .CSV ou .XLSX',
      'Manejos: Ajuste de filtro de animais ao filtrar por todos os animais ativos',
      'Manejos: Ajuste na abertura da tela de manejos ao navegar pela ficha de um animal',
      'Manejos: Melhoria da exibição da lista de manejos em dispositivos móveis',
      'Fertili Campo: Adiciona manejos que faltavam na seleção de manejos disponíveis para o usuário do Fertili Campo',
      'Lotes: Explicação detalhada do que acontece ao excluir um lote, sobre a exclusão dos custos atribuidos ao lote e manejos',
      'Animais: Atualiza visualização de histórico de manejos para visualização de cada manejo individualmente com resultados',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.7.1',
    data: '2024-01-05',
    changelog: [
      'Estoque Farmácia e Nutricional: Exibe valores de manejos e formulação',
      'Ficha do Animal: Exibe detalhes da cria, nome do pai, data de desmame, peso de desmame e nascimento',
      'Relatórios: Novo relatório de "Custo da @ Produzida"',
      'Desembolso / Cabeça: Adicionado custo por dia',
      'Arrobas Produzidas: Novos dados de perdas e transferências',
      'Lista de Todos os Manejos: Novas colunas de datas de Cadastro e Atualização',
      'Animais: Nova coluna de data da alteração da situação reprodutiva',
      'Manejos e Registros: Novos manejos de coleta de dados de melhoramento genético',
      'Finanças: Novas categorias Integralização de Capital Social, Arrendamento, Análises Laboratoriais, Encargos Sociais, Sementes, Materiais Diversos, Materiais para Ensilagem, Lubrificantes',
    ],
    mudancas_tecnicas: [
      'Aprovações: Busca de genealogia dos partos feita em uma request ao invés de uma para cada parto',
      'API: Request após renovação de token agora não envia rota modificada com parametros',
      'Glitch de scroll ao adicionar item do estoque no manejo sanitário ou nutricional',
    ]
  },
  {
    versao: '2.7.0',
    data: '2023-12-19',
    changelog: [
      'Manejos e Registros: Nova lista de manejos com todos os manejos e registros da propriedade, listados por data',
      'Financeiro: Contas, saldos, pessoas e centros de custo agora são exibidas em todas as fazendas da mesma conta',
      'Financeiro: Cartão da visão geral readicionado com resumo financeiro da propriedade',
      'Estoque Sêmen e Embriões: Renomeia código no rack para Partida',
      'Visão Geral: Novo cartão de "Desembolso por Cabeça / Mês"',
      'Estações Reprodutivas: Indicadores de prenhez por inseminador, e por partida do sêmen',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.6.6',
    data: '2023-12-11',
    changelog: [
      'KGs ou @: Configuração da propriedade para exibir peso em KGs ou @',
      'Relatório de @ Produzidas: Nova opção de visualização em KGs',
      'Relatório de Custo e Lucratividade: Nova opção de visualização em KGs',
      'Pluviometria: Nova opção de registro de chuvas por área na tela de Manejos e Registros',
      'Financeiro: Novas categorias de Despesas Agrícolas, Dividendos e Instalações Elétricas e Hidráulicas',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.6.5',
    data: '2023-12-04',
    changelog: [
      'Menu Lateral: Ícones readicionados para melhor visualização',
      'Fertili Educação: Nova tela com conteúdos educacionais, tutoriais e muito mais',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.6.4',
    data: '2023-11-30',
    changelog: [
      'Propriedades: Nova opção de ativar campos avançados de manejos reprodutivos',
      'Inseminação Artificial: Novo campo de seleção de Inseminador',
      'Inseminação Artificial: Novos campos de Bastão e Muco',
      'Diagnóstico de Gestação: Novos campos de Situação de Ovários e Tônus Uterino',
      'Diagnóstico de Gestação: Indicador "dias" no campo de período gestacional para maior clareza',
      'Pessoas e Empresas: Ajuste na exportação',
      'Compras de Animais: Ajuste na exportação',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.6.3',
    data: '2023-11-28',
    changelog: [
      'Manejos: Novo manejo de "Circunferência Escrotal", que pode ser habilitado na configuração da propriedade',
      'Estoque, Áreas e Calendário: Paginação para listas maiores que 30 itens',
      'Melhoramento Genético Qualitas: Exportação de medições e pedigree',
      'Finanças: Novas categorias "Exame Andrológico", "Farelo de Soja", "Milho Moído"',
      'Relatório de Abortos: Dados da exposição adicionado ao relatório',
      'Farmácia: Adicionada marca "Globalgen" como fornecedor',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.6.2',
    data: '2023-11-21',
    changelog: [
      'Relatório de Pesagens: Novas colunas de 2 pesagens anteriores com data, gmd e peso',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.6.1',
    data: '2023-11-20',
    changelog: [
      'Formulação: Uso de insumos para formulação de ração habilitado, com atribuição de custos',
      'Custos e Lucratividade: Atualizar os valores de compra de um insumo recalcula os valores atribuídos aos animais dos manejos',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.6.0',
    data: '2023-10-27',
    changelog: [
      'Relatórios: Novo relatório de manejos reprodutivos',
      'Pastejo Rotacionado: Nova opção de descansar todos os piquetes ao mesmo tempo',
      'Peso ao Nascer: Nova configuração disponível na edição da propriedade',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.5.7',
    data: '2023-10-11',
    changelog: [
      'Manejos: Coluna de "Animais" trocada por "Animais Hoje" e "Animais na Data" para exibir a quantidade de animais que estavam no lote na data do manejo',
      'Manejos: Novo manejo "Escore de Fezes" em lotes',
      'Relatório de Perdas: Novo relatório adicionado',
      'Calendário: Animais selecionados ao clicar em Agendar Evento agora são exibidos no cadastro do evento do calendário',
      'Finanças: Validação da soma dos centros de custos selecionados para totalizar 100%',
      'Estoque: Agora é possível "Repetir Lançamento" de lançamentos financeiros do estoque',
      'Estoque: Formulário de cadastro do item do estoque simplificado',
      'Estoque: Novo botão "Abrir" no alerta de sucesso ao salvar do item do estoque',
      'Animais: Novo botão "Abrir em Nova Aba" ao clicar com o botão direito em um item da lista de animais',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.5.6',
    data: '2023-10-09',
    changelog: [
      'Rebanho: Nova coluna opcional de "Número Pai", "Nome Pai", "Número Mãe" e "Nome Mãe" na lista de animais',
      'Finanças: Novas categorias de despesas "Cantineira", "Contabilidade" e "Participação de Lucro"',
      'Relatório de Partos: Adicionado o filtro de sexo da cria',
      'Estação Reprodutiva: Nova coluna "Data da Morte" na aba de Morte de Bezerros'
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.5.5',
    data: '2023-10-02',
    changelog: [
      'Compras e Vendas: Largura da janela se adapta à aba selecionada para melhor visualização',
      'Compras e Vendas: Valor da lista de vendas de novos lançamentos e de lançamentos editados agora inclui valores de frete, comissões, abatimentos e acréscimos',
      'Vendas: Valor de frete que não for marcado "Pago pelo comprador" não é mais adicionado ao valor total da venda',
      'Compras: Novos campos de comissões e acréscimos',
      'Importação de Pesagens: Ajuste na detecção de valores ao colar pesos na importação de pesagens',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '2.5.4',
    data: '2023-09-29',
    changelog: [
      'Áreas: Ajuste no cálculo de dias de descanso da lista de piquetes',
      'Finanças: Nova coluna de Pago em Compras e Vendas',
      'Finanças: Nova coluna de Arquivar Animais em Compras e Vendas, agora é possível marcar uma venda como paga, mas manter os animais no rebanho',
      'Finanças: Nova opção de selecionar os lançamentos da lista de compras e vendas, com opções de marcar todas como pago ou excluir os lançamentos',
      'Compras e Vendas: Novos campos de Arquivar Animais e Marcar como Pago no formulário de compras e vendas',
      'Finanças: Seleção de centro de custo agora é possível digitar o percentual exato desejado',
      'Finanças: Nova opção de "Sem Conta", que permite cadastrar lançamentos sem alterar os saldos das contas e o saldo total',
      'Finanças: Novo botão de excluir lançamento dentro da tela do lançamento',
      'Manejos: Novas colunas "Lotes Manejados" e "Áreas Manejadas" na lista de manejos',
      'Manejos: Nova opção de lançar custo do manejo na tela de manejos',
      'Cadastro de Animais: Ao cadastrar animal, a data da pesagem do nascimento é adicionada na data de nascimento ao invés da data de hoje',
      'Calendário: Alertas de animais, áreas e lotes agora são exibidos no calendário',
    ],
    mudancas_tecnicas: [
      'Animais: GMD, peso e data de pesagem foram alterados para serem metas com sincronização'
    ]
  },
  {
    versao: '2.5.3',
    data: '2023-09-25',
    changelog: [
      'Animais: Coluna de Cria ao Pé agora exibe sexo e número da cria',
      'Relatórios: Novo relatório de Intervalo Entre Partos',
      'Visão Geral: GMD Global corrigido para valor do relatório de @ Produzidas',
      'Estoque Farmácia: Exibe a unidade correta no indicador de quantidade',
      'Áreas: Novos indicadores de Área Utilizável e Área de Reserva na Visão Geral e em Áreas',
      'Estoque: Ao excluir o lançamento financeiro associado à entrada ou saída no estoque, o botão de criar lançamento financeiro é exibido novamente',
      'Animais: Novas colunas RGN, Data RGN, RGD e Data RGd na lista de animais',
      'Importação de Partos: Novas colunas de busca de crias por número',
      'Lotes: Novo manejo de observação de lotes',
      'Lotes: Nova aba de histórico do lote, exibindo observações, entra e saída de animais e a movimentação do lote entre áreas',
      'Áreas: Novo manejo de observação de áreas',
      'Áreas: Nova aba de histórico da área, exibindo observações e entra e saída de lotes',
      'Animais: Aba Custos agora é sincronizada com os dados do relatório de Custos e Lucratividade',
      'Animais: Ao clicar em um lançamento da aba Custos será aberto o manejo ou lançamento de atribuição',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.5.2',
    data: '2023-08-30',
    changelog: [
      'Relatório Custos e Lucratividade: Novo campo de % de Rendimento de Carcaça para cálculo de lucratividade em abate',
      'Manejos: Ao abrir um manejo salvo, agora é exibida a quantidade de animais dos lotes e dados de lotes na lista de áreas',
      'Animais: Ajuste do botão Ver Compra e Ver Venda da ficha do animal',
      'Estoque Nutricional: Permite digitar o valor total para obter o valor unitário do item no momento do cadastro',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.5.1',
    data: '2023-08-29',
    changelog: [
      'Finanças: Nova aba Atribuir Valores no lançamento financeiro para atribuir valores a animais, áreas ou à propriedade',
      'Custos e Lucratividade: Novos indicadores de Custos Atribuídos na tabela de animais, e na soma final',
      'Áreas: Ajuste da importação de KML gerado pelo do Google Earth',
      'Áreas: Nova aba "Pastejo Rotacionado" na lista de áreas, exibindo as movimentações de todas as áreas',
      'Áreas: Nova aba "Pastejo Rotacionado" na ficha da área, exibindo as movimentações da área selecionada',
      'Vendas: Nova aba Custos e Lucratividade dentro da ficha da venda',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.5.0',
    data: '2023-08-23',
    changelog: [
      'Manejos: Novo manejo de Secagem',
      'Manejos: Novo manejo de Observação',
      'Manejos: Novos manejos de Altura de Capim e Pastejo Rotacionado',
      'Estoque: Abre edição do lançamento financeiro ao salvar nova compra ou venda de estoque',
      'Estoque: Permite digitar o valor total para calcular o valor unitário',
      'Estoque: Novo estoque de Máquinas e Ferramentas',
      'Estoque: Novas categorias do estoque Farmácia "Vacina Brucelose" e "Vacina Aftosa"',
      'Áreas: Nova coluna "Piquete Ativo" na lista de áreas',
      'Áreas: Lista dos piquetes da área agora exibem dados de altura do capim, dias de descanso e dias de pastejo',
      'Compras e Vendas: Nova opção de cancelar o registro de compra ou venda de animais após aprovar pesagem para compra ou venda',
      'Compras e Vendas: Novos dados rápidos de último GMD dos animais vendidos, e informações sobre peso após o abate',
      'Relatório Vacas Expostas Sem Diagnóstico: Ajuste do filtro de Lotes e coluna "Tipo Exposição" especifica se foi sêmen ou embrião',
      'Animais: Nova raça "Tabanel" adicionada',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.4.10',
    data: '2023-08-08',
    changelog: [
      'Relatórios: Novo relatório de Vacas Não diagnosticadas após a exposição',
      'Relatórios: Novo relatório de Produção de Leite por Vaca',
      'Aprovações: Ajuste na aprovação de abortos',
      'Animais: Nova coluna Aptidão',
      'Lotes: Botão mover para área agora cria automaticamente o manejo de movimentação entre áreas',
      'Seletor de Datas: Altura do calendário ajustada para dispositivos móveis',
      'Finanças: Novos indicadores de Peso Morto, Peso Morto em @, e Rendimento de Carcaça em Vendas',
      'Finanças: Ativa por padrão filtro de quantidade de animais para ocultar compras e vendas sem animais selecionados',
      'Finanças: Ajuste no cálculo de totais, em que lançamentos antigos poderiam ser considerados múltiplas vezes',
      'Importação de Animais: Impede importação de espaços e caracteres especiais no campo de brinco eletrônico',
      'Importação de DG: Impede importação de espaços no campo de período gestacional',
      'Consumo Nutricional: Novos gráficos de Consumo por Lote e Consumo Diário por Lote',
      'Consumo Nutricional: Novos indicadores de Consumo Total e Consumo Diário Médio por Lote',
      'Relatórios: Previsão de partos, novo gráfico de previsão por meses',
      'Aprovações: Mensagem mais clara sobre animais excluídos no Fertili Campo',
      'Animais: Cartão de manejos sanitários da ficha do animal agora exibem manejos com itens da farmácia e manejos por lote/área',
      'Animais Transferidos: Observações dos animais transferidos não são mais perdidas',
      'Relatórios: Ajuste da coluna e no indicador de GMD desde o Nascimento no relatório de Pesagens',
      'Pesagens: Ajuste do indicador de GMD da pesagem no cadastro do manejo',
      'Pesagens: Ajuste do indicador de GMD da lista de manejos',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.4.9',
    data: '2023-08-03',
    changelog: [
      'Relatórios: Novo relatório de Vacas Aptas Pós Parto',
      'Animais: 46 novas raças adicionadas',
      'Propriedades: Formulário de cadastro de propriedades simplificado',
      'Exportação: Nova exportação de animais para o programa de melhoramento genético Qualitas',
      'Produção de Leite: Padroniza exibição do total produzido para 3 casas decimais no relatório, lista de manejos e resumo do manejo',
      'Estação Reprodutiva: Novo botão para recalcular indicadores caso desatualizados',
      'Animais: Desconsidera manejos de castração e escore para transformar novilha em nulípara',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.4.8',
    data: '2023-07-27',
    changelog: [
      'Áreas: Importação de arquivos KML e KMZ para criação de áreas e piquetes',
      'Áreas: Nova opção de editar o formato da área já desenhado',
      'Áreas: Melhoria da exibição das áreas desenhadas da fazenda',
    ],
    mudancas_tecnicas: [
      'Remove electron e suas dependências',
      'Remove dependencia do firestore, troca para messages'
    ]
  },
  {
    versao: '2.4.7',
    data: '2023-07-26',
    changelog: [
      'Finanças: Novos cartões "Contas a Pagar" e "Contas a Receber"',
      'Relatório Pesagens: Coluna "Data Primeira Pesagem" agora é exibida separadamente',
    ]
  },
  {
    versao: '2.4.6',
    data: '2023-07-25',
    changelog: [
      'Estoque Nutricional: Nova opção de cadastro de formulação de rações',
      'Visão Geral: Novo cartão de download do app Fertili Campo',
      'Campos de Texto: Impede a inserção de emojis, aspas simples e duplas',
      'Relatórios: Novo relatório de abortos, separado das estações reprodutivas',
      'Áreas: Adicionada a funcionalidade de cadastro de piquetes',
      'Áreas: Adicionada as opções do pasto "Altura de Entrada" e "Altura de Saída"',
      'Finanças: Filtro de Pessoa/Empresa agora atualiza os valores totais dos cartões',
      'Calendário: Agora é exibido no calendário Previsões de Parto, Previsões de Desmame e Pluviometria',
      'Propriedades: Nova opção "Idade para Desmame / Apartação"',
      'Relatório Pesagens: Coluna "Data anterior" agora é exibida separadamente',
      'Tabelas: Melhoria de performance',
    ],
    mudancas_tecnicas: [
      'Financas: Bloqueia ordenação por centro de custo, que causava erros',
      'Plano Controle: Bloqueia cadastro de peões',
      'Plano Controle: Bloqueia cadastro de piquetes',
      'Trial Expirado: Reativado botão de continuar com plano controle'
    ]
  },
  {
    versao: '2.4.5',
    data: '2023-07-12',
    changelog: [
      'Estação Reprodutiva: Novo filtro de "Resultado Final", na aba de Diagnósticos de Gestação',
      'Notificações: Nova opção no menu lateral para exibição das últimas notificações recebidas',
      'Minha Assinatura: Gerenciamento da assinatura adicionado às Configurações',
      'Lotes: GMD médio dos animais do lote agora é atualizado ao alterar os filtros ativos',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.4.4',
    data: '2023-07-10',
    changelog: [
      'Reordenação de Colunas: Nova funcionalidade adicionada à lista de animais',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.4.3',
    data: '2023-07-07',
    changelog: [
      'Áreas: Nova configuração "Tipo de Área"',
      'Relatórios: Novos gráficos nos cartões de detalhes dos relatórios'
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.4.2',
    data: '2023-07-04',
    changelog: [
      'Relatório Consumo Nutricional: Novo relatório',
      'Diagnóstico de Gestação: Aumenta limite de seletor de manejo de exposição para 9 horas',
      'Relatório Custo e Lucratividade: Totais são recalculados ao modificar filtros de animais',
      'Animais: Ao cadastrar animais com lote, o manejo de associação ao lote será registrado na data do cadastro, não mais na data de nascimento do animal',
      'Finanças: Nova importação de pessoas e empresas',
      'Áreas: Novo campo "Tipo de Capim" no registro das áreas',
      'Propriedades: Novo campo "Tipo de Criação" no registro das propriedades',
      'Aprovações: Utiliza campo Manejo de Exposição do DG enviado pelo Fertili Campo',
      'Finanças: Oculta aba Leite em finanças para propriedades de Corte',
      'Finanças: Permite registrar venda de leite sem valor, para posterior preenchimento',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.4.1',
    data: '2023-06-28',
    changelog: [
      'Permissões: Ajuste de inconsistência de acesso de propriedades de terceiros',
      'Ordenhas: Campo de Ordenha 1, 2 e 3 ajustado para 3 casas decimais. Ex: 11,435 kg',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.4.0',
    data: '2023-06-27',
    changelog: [
      'Importação: Nova importação de ordenhas',
      'Importação: Nova importação de transações financeiras',
      'Financeiro: Nova aba de venda de leite',
      'Notificações: Atividades do calendário do dia são notificadas 1 vez por dia no horário cadastrado em ajustes da propriedade',
      'Notificações: Aprovações enviadas pelo Fertili Campo são notificadas instantaneamente',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.11',
    data: '2023-06-19',
    changelog: [
      'Manejo: Agora é possível cadastrar castração de machos',
      'Relatórios: Melhoria do relatório de custos e lucratividade',
      'Rebanho: Ajuste ao selecionar filtro de data',
      'Aprovações: Nova aprovação de manejo nutricional em lote',
      'Aprovações: Nova aprovação de ordenha',
      'Aprovações: Nova aprovação de manejo de descarte',
      'Aprovações: Nova aprovação de manejo de castração',
      'Aprovações: Nova aprovação de manejo Sanitário por Lotes',
      'Aprovações: Renomeado "Manejos Inválidos" para "Manejos Não Preenchidos"',
      'Relatórios: Adiciona filtro de sexo no relatório de desmames',
      'Relatórios: Correção do seletor de data de safras dos relatórios',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.10',
    data: '2023-05-30',
    changelog: [
      'Tabelas: Ajuste da posição dos filtros quando acessado por smartphones',
      'Finanças: Implementa paginação na aba "Pessoas e Empresas"',
      'Finanças: Ajuste da lista de "Pessoas e Empresas" do filtro da aba "Geral"',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.9',
    data: '2023-05-11',
    changelog: [
      'Manejos: Relatório do dia agora conta com o resumo do novo Manejo Sanitário',
      'Manejos: Relatório do dia agora conta com o custo individual do Manejo Nutricional',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.8',
    data: '2023-05-10',
    changelog: [
      'Relatórios: Custo sanitário do animal adicionado ao relatório de Custos e Lucratividade',
      'Relatórios: Custo sanitário do animal adicionado à ficha do animal',
      'Estoque Farmácia: Usos pelo manejo sanitário adicionado ao histórico do item',
      'Finanças: Ajuste da quantidade exibida dos animais pendentes para compra e venda',
      'Animais: Novo cartão Genealogia e Registros na ficha do animal, agora com RGN e RGD dos pais',
      'Manejos: Novo Manejo Sanitário com integração do estoque Farmácia',
      'Manejos: Ajuste de manejo Nutricional, ao preencher dados de múltiplos animais, não sobrescrever campos de insumos não preenchidos',
      'Manejos: Ajuste de manejo Ordenha, ao preencher múltiplos animais, não sobrescrever ordenhas não preenchidas',
      'Áreas: Nova exibição das áreas no mapa, com quantidade de animais, e exibição de detalhes da área ao clicar na quantidade de animais',
      'Importação: Desabilita acesso à planilhas de importação em dispositivos móveis, devido à usabilidade inadequada',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.7',
    data: '2023-05-03',
    changelog: [
      'Consultor Virtual: Adicionado suporte à mensagens de voz',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.6',
    data: '2023-04-27',
    changelog: [
      'Consultor Virtual: Nova página com a Inteligência Artificial da Fertili',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.5',
    data: '2023-04-24',
    changelog: [
      'Relatórios: Novo relatório de Produção de Leite',
      // 'Relatórios: Novo relatório de Produção de Leite por Vaca',
      'Animais: Ajuste ao enviar todos os animais da lista ou do lote para uma venda',
      'Manejos: Ajuste do filtro de manejos realizados',
      // 'Manejo Sanitário: Novo manejo sanitário com estoque integrado',
    ],
    mudancas_tecnicas: [
      'Log de Acesso: Enviado ao logar e início de sessão',
      'Login: Diminui a altura da tela de login em janelas menores',
      'Logout: Limpa localStorage ao sair da conta',
    ]
  },
  {
    versao: '2.3.4',
    data: '2023-04-12',
    changelog: [
      'Animais: Nova coluna de Pelagem',
      'Animais: Novas informações "Pelagem", "RGN", "RGD" adicionada à ficha do animal',
      'Animais: Observação é exibida por inteiro na ficha do animal',
      'Animais: Nova coluna "Duração" do manejo nutricional na aba custos do animal',
      'Compras e Vendas: Novo botão "Ver Ficha do Animal" na lista de animais',
      'Manejos: Remove fazenda atual da lista de fazendas da movimentação entre fazendas',
    ],
    mudancas_tecnicas: [
      'Raças: Padronização de todas as raças em todas as paginas',
    ]
  },
  {
    versao: '2.3.3',
    data: '2023-04-07',
    changelog: [
      'Animais: Novos tipos de pelagens adicionadas',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.2',
    data: '2023-04-06',
    changelog: [
      'Relatórios: Novo relatório de custo e lucratividade por animal',
      'Genealogia: Novas colunas de busca de pais por numeração na importação de genealogia',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.1',
    data: '2023-03-29',
    changelog: [
      'Animais Externos: Nova janela de importação de animais externos',
      'Genealogia: Nova janela de importação de Genealogia',
      'Relatório: Análise de recebimento e de pagamentos agora exibe o total pago e não pago',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.3.0',
    data: '2023-03-28',
    changelog: [
      'Ficha do Animal: Cartão de manejo reprodutivo agora exibe touro da inseminação e monta, numero da cria e mais',
      'Relatório de Desmames: Novos campos de genealogia, pesos ajustados e gmd'
    ],
    mudancas_tecnicas: [
      'Mortalidade: Ajustes dos labels que faltavam',
    ]
  },
  {
    versao: '2.2.2',
    data: '2023-03-21',
    changelog: [
      'App Mobile: Campos numéricos agora exibem o botão para adicionar vírgulas',
      'Login: Ao tentar cadastrar com conta que já existe, será alertado que conta existe e tentará entrar na conta com os dados preenchidos',
      'Estoque: Novo estoque de medicamentos "Farmácia"',
      'Genealogia: Exibe na árvore genealógica os avós dos animais externos',
      'Finanças: Novo campo "Forma de Pagamento" nas transações',
      'Estações Reprodutivas: Ajuste do cálculo de indicadores de prenhez e desmame',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.2.1',
    data: '2023-03-15',
    changelog: [
      'Finanças: Novo campo para anexos em PDF',
      'Relatórios: Estações Reprodutivas, corrige cálculo de perda pre-parto',
      'Impressão: Nome da Fazenda incluso no cabeçalho da impressão',
      'Calendário: Novo botão "Novo Manejo" em evento agendado com animais',
      'Notificações: Nova notificação push ao receber solicitação de aprovação',
      'Animais: Novo cartão de "Peso Médio"',
      'Lote: Novo cartão de "Peso Médio"',
      'Finanças: Novas categorias de despesas "Empréstimo", "Custeio", "Crédito Rural" e "Financiamento Bancário"',
      'Finanças: Busca de categoria agora busca pelo nome do grupo da categoria',
      'Finanças: Busca de categoria agora evidencia itens buscados que batem com o termo buscado',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.2.0',
    data: '2023-03-13',
    changelog: [
      'Novo plano "Fertili Controle" disponibilizado',
      'Tabelas: GMD agora é exibido com 3 casas decimais',
      'Relatório do Manejo: GMD agora é sincronizado com o valor calculado na tela do manejo',
      'Relatório de Movimentações: Ajustes de colunas, totais das colunas e contador de animais vendidos corrigido',
      'Manejos: Novo manejo de Descarte',
      'Manejo: Ordenação manual de animais ajustada',
      'Manejo: Preenchimento do lote dos animias ao preencher todos os animais com manejo de Movimentação entre Fazendas',
      'Estoque: Evidencia o botão "Editar Lançamento Financeiro", para facilitar inclusão em centro de custo e categorias',
      'Estoque: Custo dos lançamentos são recalculados ao remover entrada ou saída manual',
      'Estoque: Aumenta quantidade de casas decimais do campo de valor unitário do lançamento nutricional',
      'Estações Reprodutivas: Ordenação dos animais ajustada',
      'Estações Reprodutivas: Ajuste no cálculo de Concepção por Exposição',
      'Raças: Wagyu adicionada à lista de raças',
      'Boas Vindas: Novo questionário após o cadastro',
      'Notificações: Notificações Push readicionadas ao sistema',
    ],
    mudancas_tecnicas: [
      'Implementado o cancelamento automático de requisições ao chamar a mesma rota',
      'Aprovação de Sanitário: Nome do procedimento sanitário na coluna correta',
    ]
  },
  {
    versao: '2.1.4',
    data: '2023-02-10',
    changelog: [
      'Visão Geral: Melhoria do design dos cartões e gráficos',
      'Animais: Nova aba de custos do animal',
      'Animais: Contador de animais do menu lateral se atualiza ao excluir animal',
      'Relatório: Estações reprodutivas, filtros da lista alteram os valores dos cartões',
      'Relatório: Relatório do manejo, novas colunas "Previsão de Parto" e GMD',
      'Manejos: Nova opção de mover todos os manejos da data para outro dia',
      'Manejos: Nova opção de limpar todos os manejos da data',
      'Manejos: Nova opção de agendar evento com todos os animais da data',
      'Manejos: Ajuste no filtro de manejos sanitários preenchidos',
      'Finanças: Retorna a opção de comprovante de compra e venda',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.1.3',
    data: '2023-02-01',
    changelog: [
      'Relatórios: Previsão de partos agora inicia com o seletor de datas começando a partir de 2 meses atrás',
      'Relatórios: Novos relatórios financeiro de Análise de Pagamentos e Análise de Recebimentos',
      'Relatórios: Novos relatório de manejos na página de cadastro de manejos',
      'Manejos: Ajuste na combinação de filtros de manejos preenchidos',
      'Manejos: Nova opção de agendar manejos com os animais selecionados do manejo',
      'Estoque de Sêmen e Embriões: Adicionada a marca Alta Genetics',
      'Seletor de Datas: Novas opções rápidas de Safra e Ano',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.1.2',
    data: '2023-01-25',
    changelog: [
      '360 de Bolso renomeado para Fertili Campo nas configurações de Usuários',
      'Importação: Planilha integrada agora abre em tela cheia, e melhorias para evitar erros de importação com alterações de nomes de colunas',
      'Manejos: Ao abrir o teclado na tela de manejos no celular, agora existe mais espaço disponível para interagir com os campos',
      'Manejos: Adicionada a possibilidade de reordenar os animais do manejo',
      'Áreas: Nome da área adicionado à impressão da área',
      'Áreas: Total de animais e lotes adicionado à impressão da área',
      'Lotes: Total de animais adicionado à impressão do lote',
      'Lotes: Novo campo de "Observação" no cadastro de lotes',
      'Animais: Novo seletor de cadastro único ou múltiplo de animais',
      'Finanças: Adicionada paginação no seletor de Recebedor ou Pagador dos lançamentos financeiros',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.1.1',
    data: '2023-01-18',
    changelog: [
      'Exportação: Números em arquivos .XLSX corretamente exportados para Excel e Drive',
      'Financas: Opção de paginação em compra e vendas',
      'Usuário: Atualização da quantidade de animais do cartão de dados do usuário na barra lateral ao cadastrar animais',
      'Usuário: Tratamento de erro da quantidade máxima de animais cadastrados ao cadastrar animal único, aprovar cadastro e importar animais',
      'Cadastro de Animais: Removida a possibidade de fechar a janela de cadastro de animais clicando fora da janela',
      'Aprovações: Aprovação de parto agora registra genealogia',
      'Aprovações: Aprovação de registro de morte agora registra imagens',
      'Aprovações: Exibição de animal como inválido se tiver sido excluído no Fertili Campo',
      'Aprovações: Adicionada a aprovação de Manejos Sanitários',
      'Ficha do Animal: Mais dados das crias, como fazenda que se encontra e situação atual',
      'Relatórios: Previsão de partos agora somente exibe vacas que ainda estão prenhes',
      'Filtros: Previne campos de filtro de aparecerem atrás do teclado na versão para celular',
      'Animais: Se tiver animais selecionados e clicar por engano na linha do animal não abre mais a ficha do animal',
      'Lote: Ao clicar em Novo Manejo em lote sem animais selecionados, todos os animais de todas as páginas serão enviados ao manejo',
      'Estoque: Novos tipos de saída "Desperdício" e "Perda"',
      'Finanças: Lançamento do saldo inicial agora vêm com a categoria "Outros"',
      'Finanças: Nova coluna da tabela e exportação "Descrição"',
      'Finanças: Cartão de saldo mais largo para caber números maiores',
    ],
    mudancas_tecnicas: [
      'Reprovação de animal não está gerando o meta para não aparecer mais o animal no Fertili Campo',
      'Paginação, vir todas as paginas com a primeira pagina selecionada por padrão',
      'Prevenção de erro ao cancelar popover de limpar manejo'
    ]
  },
  {
    versao: '2.1.0',
    data: '2023-01-14',
    changelog: [
      'Tabelas: Nova opção de exportação em .XLSX e .CSV',
      'Tabelas: Melhorias de filtros na versão para celular',
      'Tabelas: Corrigida a ordenação de GMD',
      'Tabelas: Possibilidade de filtrar números negativos',
      'Animais: Adicionada à ficha dos animais a opção de ampliar a foto adicionada',
      'Animais: As imagens enviadas agora têm uma resolução maior',
      'Manejos: Nova opção de mover manejos para outra data',
      'Manejos: Nova opção de seleção de lote na propriedade destino do manejo de Movimentação entre Fazendas',
      'Paginação: Melhoria do comportamento ao trocar filtros durante navegação por páginas das tabelas',
      'Compras e Vendas: Correção da exibição e edição de valores de preço por @, Unidade e Kg',
      'Compras e Vendas: Ajuste de lançamentos que não carregavam os detalhes',
    ]
  },
  {
    versao: '2.0.10',
    data: '2023-01-11',
    changelog: [
      'Seletor de Data: Nova navegação rápida entre anos e meses',
      'Finanças: Novo filtro de categorias de lançamentos com seleção múltipla e busca',
      'Finanças: Permite salvar lançamento com 1 centro de custo somente',
      'Finanças: Ajuste na remoção de centros de custos',
      'Importação de Animais: Corrige geração automática de números e armazenamento de brincos eletrônicos',
    ]
  },
  {
    versao: '2.0.9',
    data: '2022-12-20',
    changelog: [
      'Animais: Lista de manejos sanitários da ficha do animal corrigida',
      'Calendário: Permite editar lançamentos e adiciona descrição dos eventos à lista',
      'Animais: Quantidade de partos adicionada manualmente sem registro em manejo agora é considerada na categoria das fêmeas',
      'Animais: Correção na edição manual de genealogia',
      'Animais: Permite edição da data de entrada na fazenda',
      'Relatórios: Previsão de Partos, remove vacas que tiveram manejo de DG negativo registrado após o DG positivo',
      'Relatórios: Estações Reprodutivas, remove vacas que não tiveram manejo de exposição',
      'Relatórios: 2023 no relatório de Fluxo de Caixa',
      'Finanças: Nova categoria de despesa de Seguro de Animais',
    ]
  },
  {
    versao: '2.0.8',
    data: '2022-12-14',
    changelog: [
      'Animais: Nova raça Bonsmara adicionada',
      'Relatórios: Dados de Nulíparas adicionados ao relatório Estoque Mensal',
      'Lotes: Na ficha do lote, ao clicar no nome da área será aberta a ficha da área',
      'Seletor Lotes: Adicionada opções de ordenação e filtros',
      'Impressão: Funcionalidade adicionada à dispositivos móveis',
    ]
  },
  {
    versao: '2.0.7',
    data: '2022-12-12',
    changelog: [
      'Finanças: Nova coluna e filtro de Centro de Custo',
      'Finanças: Filtro de transferências, e filtro múltiplo de tipo de transação',
      'Relatórios: Novo relatório de fluxo de caixa',
      'Relatórios: Seletor de período do relatório de Mortalidade corrigido',
      'Relatórios: Seletor de período do relatório de Previsão de Parto corrigido',
      'Relatórios: Gráficos melhorados na versão para smartphones',
      'Relatórios: Cartões de informações agora são incluídos nas impressões',
      'Tabelas: Seletor de página melhorado, com última e primeira página sempre disponível',
      'Propriedade: Possibilidade de customizar o tempo de gestação dos animais da propriedade',
      'Animais: Categoria da ficha padronizada com a categoria da lista',
      'Visão Geral: Opção de ver todo o histórico de atualizações',
      'Partos: Genealogia corrigia ao salvar novos partos',
    ]
  },
  {
    versao: '2.0.6',
    data: '2022-12-09',
    changelog: [
      'Manejos: Correção na adição de múltiplos Manejos Sanitários do mesmo tipo',
      'Seletor de animais: Busca rápida por número',
      'Estoque: Lançamento de saída financeira ao registrar lançamento inicial de compra ao cadastrar item de estoque',
    ]
  },
  {
    versao: '2.0.5',
    data: '2022-11-24',
    changelog: [
      'Novo Manejo Nutricional',
      'Novo manejo de movimentação de lotes entre áreas',
      'Possibilidade de cadastrar manejos em Lotes e Áreas',
      'Ajuste da associação de genealogia ',
      'Novo módulo Estoque Nutricional',
      'Novo mapa integrado à pagina de Áreas',
      'Estações Reprodutivas: Filtro e ordenação no relatório',
      'Página "Animais" renomeado para "Rebanho"',
      'Página "Lotes" agora é uma aba da página "Rebanho"',
      'Página "Estações Reprodutivas" movida para "Relatórios"',
      'Em Controle Pluviométrico agora é possível remover registros cadastrados',
      'Exportação de .csv, ajuste de números exportados',
      'Rebanho: Dados dos cartões de informações de animais são atualizados com os filtros',
      'Finanças: Dados dos cartões de informações de saldo do período selecionado são atualizados com os filtros',
    ]
  },
  {
    versao: '2.0.4',
    data: '2022-11-18',
    changelog: [
      'Animais: Habilitado o cadastro de Composição racial para animais da fazenda',
      'Financeiro: Nova categoria "Pró-Labore" em despesas'
    ]
  },
  {
    versao: '2.0.3',
    data: '2022-11-16',
    changelog: [
      'Manejos e Animais: Seleção de todos os animais em um click',
      'Visão Geral: Indicador "@ Produzida / Rebanho Médio" adicionado',
      'Visão Geral: Indicador "@ Produzida / ha" adicionado',
      'Relatórios: Indicador "@ Produzida / Rebanho Médio" adicionado',
      'Relatórios: Indicador "@ Produzida / ha" adicionado',
      'Manejos: Ajustado o filtro de animais sem lote na tela de manejo',
      'Relatórios: Resumo de sexos no relatório de partos',
      'Relatórios: Partos de 2 crias adicionados ao relatório de partos',
      'Visão Geral: Cartão "Produção Leiteira" removido das fazendas de corte',
      'Estações Reprodutivas: Resumo de sexo dos partos da estação reprodutiva',
      'Estações Reprodutivas: Novos dados de exposições com uso de embriões'
    ]
  }
]
